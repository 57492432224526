import { IPromise } from 'q';
import { IAPIResource, ObjectId, TSFixMe } from '../../..';
import {
  Identified,
  LimitParams,
  SortParams,
} from '../../Utils/CRUD/crud-service';

type SQLDatabase = Database & {
  sqlBatch?: (queries, successCallback, errorCallback) => ng.IPromise<void>;
};
type DatabaseFunction = () => ng.IPromise<SQLDatabase>;
type FilterFunction = (...args) => boolean;
type FilterParamsValue = string[] | RegExp | string | boolean;
type SanitizedFilterParamsValue =
  | string[]
  | RegExp
  | string
  | BooleanInteger
  | FilterFunction;
export type FilterParams = Record<string, FilterParamsValue>;
type SanitizedFilterParams = Record<string, SanitizedFilterParamsValue>;
type SqlQuery = {
  query: string;
  params?: (string | string[] | BooleanInteger)[];
};
type Partition = {
  self: SanitizedFilterParams;
  ext: string[];
};
type BooleanInteger = 0 | 1;

export interface LocalDatabaseInterface<T> {
  listBackUp(limitParams?: LimitParams): ng.IPromise<Identified<T>[]>;
  getBackUp(entryId?: ObjectId): ng.IPromise<Identified<T>>;
  queryBackUp(
    filtersParams: FilterParams,
    limitParams: LimitParams,
    sortParams: SortParams,
    listComparator?: (...arg: any) => boolean
  );
  saveBackUp(resourceId: ObjectId, resource: T): ng.IPromise<Identified<T>>;
  updateBackUp(resource: T): ng.IPromise<Identified<T>>;
  removeBackUp(resourceId: ObjectId): ng.IPromise<SqlResultSet>;
  removeQueryBackUp(
    filtersParams: SanitizedFilterParams
  ): ng.IPromise<SqlResultSet>;
  bulkDocsBackUp(_data: T[]): ng.IPromise<SqlResultSet[] | void>;
  batch(queries: SqlQuery[]): ng.IPromise<unknown>;
  execute(sqlStatement: string, bindings?: any[]): ng.IPromise<SqlResultSet>;
}
export type AngularSqlFactory<T> = (
  tableName: string,
  databaseFn: DatabaseFunction,
  options
) => LocalDatabaseInterface<T>;

const PARAMS_LIMIT = 100;
const NB_PARAMS_MAX = 300;

/* @ngInject */
export function AngularSQLFactory<T extends IAPIResource>(
  $log: ng.ILogService,
  $q: ng.IQService
): AngularSqlFactory<T> {
  class AngularSQLService {
    backUpName: string;
    helpers: {
      indexed_fields: string[];
    };
    backUpDB: DatabaseFunction;

    constructor(
      private tableName: string,
      private databaseFn: DatabaseFunction,
      private options: {
        indexed_fields?: string[];
      } = {}
    ) {
      const indexedFields = options.indexed_fields || [];

      this.backUpName = tableName;
      this.helpers = { indexed_fields: indexedFields };
      this.backUpDB = databaseFn;
    }

    listBackUp(limitParams?: LimitParams): ng.IPromise<Identified<T>[]> {
      const request = this.prepareSelect(this.backUpName, {}, limitParams);

      return this.execute(request.query)
        .then((results) => this.transformResults(results))
        .catch((err) => {
          $log.error('[Backup] List', this.backUpName, ':', err.message);
          throw err;
        });
    }

    getBackUp(entryId?: ObjectId): ng.IPromise<Identified<T>> {
      if (!entryId) {
        throw new Error('You need to provide an id');
      }

      const request = this.prepareSelect(this.backUpName, { id: entryId });

      return this.execute(request.query, request.params)
        .then((doc) =>
          doc.rows.length
            ? this.unserializePayloadColumn(doc, 0)
            : $q.reject({ message: 'Not Found', status: 404 })
        )
        .catch((err) => {
          $log.error('[Backup] Get', this.backUpName, ':', err.message);
          throw err;
        });
    }

    queryBackUp(
      filtersParams: FilterParams = {},
      limitParams: LimitParams = {},
      sortParams: SortParams = [],
      listComparator?: (...arg: any) => boolean
    ) {
      const indexedFields = this.helpers.indexed_fields;
      return this.getColumnsFromTable(this.backUpName)
        .then((results) => {
          const existingColumnsNames = [
            ...Array(results.rows.length).keys(),
          ].map((num) => results.rows.item(num)['name']);
          return sortParams.filter((s) => existingColumnsNames.includes(s.key));
        })
        .then((allowedSortParams) => {
          // filtersParams
          // |> sanitizeFiltersValues - remove boolean values
          // |> pickIndexed           - keep only indexed cols related filters
          // |> partitionByQuerySize  - split high sized filtervalues
          const sanitizedFiltersParams =
            this.sanitizeFiltersValues(filtersParams);
          const indexedFiltersParams = this.pickIndexed(
            indexedFields,
            sanitizedFiltersParams
          );
          const partitionnedFiltersParams =
            this.partitionByQuerySize(indexedFiltersParams);
          const tmpQueries = this.buildInsertTmpTablesQueries(
            this.backUpName,
            partitionnedFiltersParams
          );
          const tmpTablesQueries = tmpQueries.reduce(
            (arr, queries) => arr.concat(queries),
            []
          );
          const nonIndexedParams = this.pickNonIndexed(
            indexedFields,
            sanitizedFiltersParams
          );
          const hasOnlyNonIndexedFilters = Boolean(
            Object.keys(sanitizedFiltersParams).filter(
              (p) => indexedFields.indexOf(p) !== -1
            ).length === 0 && Object.keys(nonIndexedParams).length
          );

          // building the temp tables if needed
          const batchPromise = tmpTablesQueries.length
            ? this.batch(tmpTablesQueries)
            : $q.when();

          return batchPromise
            .then(() => {
              const query = this.prepareSimpleQuery(
                this.backUpName,
                partitionnedFiltersParams,
                hasOnlyNonIndexedFilters ? {} : limitParams,
                allowedSortParams
              );
              const identity = (d) => d;
              const inMemoryLimit = hasOnlyNonIndexedFilters
                ? this.inMemoryPaginate(limitParams)
                : identity;

              return this.execute(query.query, query.params).then((docs) => {
                const data = this.transformResults(docs);

                return inMemoryLimit(
                  this.inMemoryFilter(data, nonIndexedParams, listComparator)
                );
              });
            })
            .catch((err) => {
              $log.error('[Backup] Query', this.backUpName, ':', err.message);
              throw err;
            });
        });
    }

    saveBackUp(
      resourceId: ObjectId,
      resource: Identified<T>
    ): ng.IPromise<Identified<T>> {
      const indexedFields = this.helpers.indexed_fields;
      // Request
      const request = this.prepareInsertRequest(
        [resource],
        indexedFields,
        this.backUpName
      );

      return this.execute(request.query, request.params)
        .then(() => resource)
        .catch((err) => {
          $log.error('[Backup] Save', this.backUpName, ':', err.message);
          throw err;
        });
    }

    updateBackUp(resource: Identified<T>): ng.IPromise<Identified<T>> {
      const indexedFields = this.helpers.indexed_fields;
      const request = this.prepareUpdateRequest(
        resource,
        indexedFields,
        this.backUpName
      );

      return this.execute(request.query, request.params)
        .then(() => resource)
        .catch((err) => {
          $log.error('[Backup] Update', this.backUpName, ':', err.message);
          throw err;
        });
    }

    removeBackUp(resourceId: ObjectId): ng.IPromise<SqlResultSet> {
      const request = this.prepareDeleteRequest(
        { id: resourceId },
        this.backUpName
      );

      return this.execute(request.query, request.params).catch((err) => {
        $log.error('[Backup] Remove', this.backUpName, ':', err.message);
        throw err;
      });
    }

    removeQueryBackUp(
      filtersParams: SanitizedFilterParams
    ): ng.IPromise<SqlResultSet> {
      const request = this.prepareDeleteRequest(filtersParams, this.backUpName);

      return this.execute(request.query, request.params).catch((err) => {
        $log.error('[Backup] Remove', this.backUpName, ':', err.message);
        throw err;
      });
    }

    bulkDocsBackUp(_data: T[]): ng.IPromise<SqlResultSet[] | void> {
      const indexedFields = this.helpers.indexed_fields;
      const queries = [] as SqlQuery[];

      // Deleted
      const deleteIds = _data
        .filter((entry) => entry._deleted)
        .map((entry) => entry.id as ObjectId);
      const upsertData = _data.filter((entry) => !entry._deleted);

      // Delete what has to be deleted
      if (deleteIds.length) {
        queries.push(
          this.prepareDeleteRequest({ id: deleteIds }, this.backUpName)
        );
      }
      // Upsert what has to be upserted
      if (upsertData.length) {
        queries.push(
          this.prepareInsertRequest(upsertData, indexedFields, this.backUpName)
        );
      }

      return queries.length
        ? $q
            .all(
              queries.map((query) => this.execute(query.query, query.params))
            )
            .catch((err) => {
              $log.error('[Backup] Bulk', this.backUpName, ':', err.message);
              throw err;
            })
        : $q.when();
    }

    batch(queries: SqlQuery[]): ng.IPromise<unknown> {
      const q = $q.defer();

      this.backUpDB().then((database) => {
        return database.sqlBatch
          ? database.sqlBatch(
              // typedef does not know about it
              queries.map((query) => [query.query, query.params || []]),
              (res) => q.resolve(res),
              (err) => q.reject(err)
            )
          : batchFallback(database).then(q.resolve).catch(q.reject);
      });

      return q.promise;

      /**
       * @param   {Database}          database -
       * @returns {ng.IPromise<void>}          -
       */
      function batchFallback(database) {
        const qFallback = $q.defer();

        database.transaction(
          (tx) => {
            queries.forEach((query) => {
              tx.executeSql(query.query, query.params || []);
            });
          },
          qFallback.reject,
          qFallback.resolve
        );

        return qFallback.promise;
      }
    }

    execute(sqlStatement: string, bindings?: any[]): ng.IPromise<SqlResultSet> {
      const q = $q.defer<SqlResultSet>();

      this.backUpDB().then((database) => {
        database.transaction((tx) => {
          tx.executeSql(
            sqlStatement,
            bindings,
            (transaction, resultSet) => {
              q.resolve(resultSet);
            },
            (transaction, error) => {
              q.reject(error);
              return false;
            }
          );
        });
      });
      return q.promise;
    }

    /* SELECT Utils */

    private prepareSimpleQuery(
      tableName: string,
      queryAsObject: Partition,
      limitParams: LimitParams,
      sortParams: SortParams
    ): SqlQuery {
      const getSelfQuery = (filtersParams) =>
        Object.keys(filtersParams).map((key) =>
          this.applyDefaultOperator(key, filtersParams[key])
        );
      const getExtQuery = (self) =>
        Object.keys(self).map((column) => {
          const cTmpName = 'tmp_' + tableName + '_' + column;

          return column + ' IN (SELECT value FROM ' + cTmpName + ')';
        });
      const getSimpleQuery = (queryObject) => {
        const statement = 'SELECT * FROM ' + tableName;
        const queries = ([] as string[]).concat(
          getSelfQuery(queryObject.self),
          getExtQuery(queryObject.ext)
        );
        const whereDefinition = queries.length ? ' WHERE ' : '';
        const andDefinition = queries.join(' AND ');
        const dataDefinition = '' + whereDefinition + andDefinition;
        const query = statement + dataDefinition;
        const sortedQuery = this.addOrderByClause(query, sortParams);
        const limitDefinition = this.addPaginationClauses(
          sortedQuery,
          limitParams
        );

        return limitDefinition + ';';
      };

      return {
        query: getSimpleQuery(queryAsObject),
        params: Object.keys(queryAsObject.self).reduce(
          (arr, column) =>
            arr.concat(
              this.filterValuesToSQLBindingsValues(queryAsObject.self[column])
            ),
          [] as (string | string[] | BooleanInteger)[]
        ),
      };
    }

    private prepareSelect(
      tableName: string,
      filtersParams: SanitizedFilterParams,
      limitParams: LimitParams = {}
    ): SqlQuery {
      const statement = 'SELECT * FROM ' + tableName;
      const query = this.addWhereClause(statement, filtersParams);
      const queryLimit = this.addPaginationClauses(query, limitParams);
      const queryParamsValues = this.extractValues(filtersParams);

      return {
        query: queryLimit,
        params: queryParamsValues,
      };
    }

    private prepareSelectAs(fields: string[]): string {
      const allFields = fields.map((field) => '? as ' + field).join(', ');

      return 'SELECT ' + allFields;
    }

    /* INSERT Utils */

    private prepareInsertUnionQuery(
      data: T[],
      fields: string[] | string
    ): string {
      const arrFields = ([] as string[]).concat(fields);
      const selectAs = this.prepareSelectAs(arrFields);

      return data
        .map((data, index) =>
          index === 0
            ? selectAs
            : 'UNION ALL SELECT ' + this.slotsString(arrFields)
        )
        .join(' ');
    }

    private prepareInsertRequest(
      entries: T[],
      indexedFields: string[],
      tableName: string
    ): SqlQuery {
      const statement = 'INSERT OR REPLACE INTO ' + tableName;
      const allFields = ['id', 'payload'].concat(indexedFields);
      const fieldsRequest = '(' + allFields.join(', ') + ')';
      const params =
        entries.length > 1
          ? this.prepareInsertUnionQuery(entries, allFields)
          : 'VALUES (' + this.slotsString(allFields) + ')';

      return {
        query: statement + ' ' + fieldsRequest + ' ' + params,
        params: entries
          .map((entry) =>
            ([entry.id || entry._id] as (string | 0 | string[] | 1)[]).concat(
              this.prepareRequestValues(entry, indexedFields)
            )
          )
          .reduce((arr, upsert) => arr.concat(upsert), []),
      };
    }

    private buildInsertTmpTablesQueries(
      tableName: string,
      filtersParamsPartition: Partition
    ): SqlQuery[][] {
      return Object.keys(filtersParamsPartition.ext).map((key) => {
        const cTmpName = 'tmp_' + tableName + '_' + key;
        const dropTableQuery = 'DROP TABLE IF EXISTS ' + cTmpName;
        const createTableQuery =
          'CREATE TABLE IF NOT EXISTS ' + cTmpName + ' (value TEXT)';
        const insertQueries = this.buildInsertQueries(
          cTmpName,
          'value',
          filtersParamsPartition.ext[key]
        );

        return [{ query: dropTableQuery }, { query: createTableQuery }].concat(
          insertQueries
        );
      });
    }

    private buildInsertQueries(
      tableName: string,
      column: string,
      filterValues: string[]
    ): SqlQuery[] {
      return this.chunck(filterValues, NB_PARAMS_MAX).map((fvChunck) => {
        const query = 'INSERT INTO ' + tableName;
        const sliceQuery = this.prepareInsertUnionQuery(fvChunck, column);

        return {
          query: query + ' ' + sliceQuery,
          params: fvChunck,
        };
      });
    }

    /* UPDATE Utils */

    private prepareUpdateRequest(
      resource: T,
      indexedFields: string[],
      tableName: string
    ): SqlQuery {
      const statement = 'UPDATE ' + tableName;
      const fields = ['payload'].concat(indexedFields);
      // Data
      const requestValues = this.prepareRequestValues(resource, indexedFields);
      // Request
      const dataDefinition = fields.map((field) => field + '=?').join(', ');
      const resourceId = resource.id || resource._id;

      return {
        query: statement + ' SET ' + dataDefinition + ' WHERE id=?',
        params: requestValues.concat([resourceId as ObjectId]),
      };
    }

    /* DELETE Utils */

    private prepareDeleteRequest(
      filtersParameters: SanitizedFilterParams,
      tableName: string
    ): SqlQuery {
      const statement = 'DELETE FROM ' + tableName;
      const query = this.addWhereClause(statement, filtersParameters);

      return {
        query: query,
        params: this.extractValues(filtersParameters),
      };
    }

    /* WHERE Utils */

    private addWhereClause(
      sqlQuery: string,
      filtersParams: SanitizedFilterParams = {}
    ): string {
      const hasFilters = Object.keys(filtersParams).length > 0;

      return hasFilters
        ? sqlQuery + ' WHERE ' + this.generateWhereExpression(filtersParams)
        : sqlQuery;
    }

    private generateWhereExpression(
      filtersParams: SanitizedFilterParams = {}
    ): string {
      return this.joinFilterClauses(
        Object.keys(filtersParams).map((key) =>
          this.applyDefaultOperator(key, filtersParams[key])
        )
      );
    }

    /* Pagination utils */

    private addPaginationClauses(
      query: string,
      limitParams: LimitParams = {}
    ): string {
      let ammendedQuery = query;

      if (limitParams.limit) {
        ammendedQuery += ' LIMIT ' + limitParams.limit;
      }
      if (limitParams.offset) {
        ammendedQuery += ' OFFSET ' + limitParams.offset;
      }
      return ammendedQuery;
    }

    private inMemoryPaginate(
      limitParams: LimitParams = {}
    ): (data: T[]) => T[] {
      return (data: T[]) => {
        const start = limitParams.offset;
        const nb = limitParams.limit;

        if (start !== undefined && nb !== undefined) {
          return data.slice(start, start + nb);
        }
        if (start !== undefined) {
          return data.slice(start);
        }
        return data.slice(0, nb);
      };
    }

    /* Filters Utils */

    private sanitizeFiltersValues(
      filtersParams: FilterParams
    ): SanitizedFilterParams {
      return Object.keys(filtersParams).reduce((filtersHash, filterKey) => {
        filtersHash[filterKey] = this.boolToInteger(filtersParams[filterKey]);
        return filtersHash;
      }, {});
    }

    private filterValuesToSQLBindingsValues(
      filterValue: SanitizedFilterParamsValue
    ): string | string[] | BooleanInteger;
    private filterValuesToSQLBindingsValues(
      filterValue: FilterParamsValue
    ): string | string[] | boolean;
    private filterValuesToSQLBindingsValues(
      filterValue: SanitizedFilterParamsValue | FilterParamsValue
    ):
      | (string | string[] | BooleanInteger | FilterFunction)
      | (string | string[] | boolean) {
      return this.isRegExp(filterValue)
        ? '%' + filterValue.source + '%' // regexp source need to be used as string with %%
        : filterValue;
    }

    private inMemoryFilter(
      resources: T[],
      filtersParams: SanitizedFilterParams = {},
      listComparator?: (...arg: any) => boolean
    ): T[] {
      if (!Object.keys(filtersParams).length) {
        return resources;
      }

      const path = (p, value) => {
        let index = 0;
        const length = p.length;

        while (value !== null && value !== undefined && index < length) {
          value = value[p[index++]];
        }
        return index && index === length ? value : undefined;
      };

      return resources.filter((resource) => {
        // eslint-disable-next-line complexity
        return Object.keys(filtersParams).every((filterKey) => {
          const filterPath = filterKey.split('.');
          const resourceValue = path(filterPath, resource);
          const filterValue = filtersParams[filterKey];

          if (angular.isArray(filterValue) && angular.isArray(resourceValue)) {
            return filterValue.every((filter) =>
              resourceValue.some(
                (value) =>
                  (listComparator && listComparator(filter, value)) ||
                  angular.equals(filter, value)
              )
            );
          }

          // In for array
          if (angular.isArray(filterValue)) {
            return filterValue.some((value) =>
              angular.equals(value, resourceValue)
            );
          }

          if (angular.isArray(resourceValue)) {
            return resourceValue.some((value) =>
              angular.equals(value, filterValue)
            );
          }

          if (this.isRegExp(filterValue)) {
            return new RegExp(filterValue, 'i').test(resourceValue);
          }

          if (angular.isFunction(filterValue)) {
            return filterValue(resourceValue);
          }

          return angular.equals(filterValue, resourceValue); // Equal for single value
        });
      });
    }

    /* Order Utils */

    private addOrderByClause(
      sqlQuery: string,
      sortParams: SortParams = []
    ): string {
      const hasSort = sortParams.length > 0;

      return hasSort
        ? sqlQuery + ' ORDER BY ' + this.generateOrderByExpression(sortParams)
        : sqlQuery;
    }

    private generateOrderByExpression(sortParams: SortParams = []): string {
      return sortParams
        .map(({ key, desc, nullsLast }) => {
          const sortOrder = desc ? ' DESC' : '';
          return nullsLast
            ? `${key} IS ${desc ? 'NOT' : ''} NULL, ${key}${sortOrder}`
            : `${key}${sortOrder}`;
        })
        .join(',');
    }

    /* Utils */

    private pickIndexed(
      indexedColumns: string[],
      filtersParams: SanitizedFilterParams
    ): SanitizedFilterParams {
      const isIndexed = (filterKey: string): boolean =>
        indexedColumns.indexOf(filterKey) !== -1 || filterKey === 'id';

      return Object.keys(filtersParams).reduce((indexedQueries, filterKey) => {
        if (isIndexed(filterKey)) {
          indexedQueries[filterKey] = filtersParams[filterKey];
        }
        return indexedQueries;
      }, {});
    }

    private pickNonIndexed(
      indexedColumns: string[],
      filtersParams: SanitizedFilterParams
    ): SanitizedFilterParams {
      const isIndexed = (filterKey: string): boolean =>
        indexedColumns.indexOf(filterKey) !== -1 || filterKey === 'id';

      return Object.keys(filtersParams).reduce((indexedQueries, filterKey) => {
        if (!isIndexed(filterKey)) {
          indexedQueries[filterKey] = filtersParams[filterKey];
        }
        return indexedQueries;
      }, {});
    }

    private partitionByQuerySize(
      filterParams: SanitizedFilterParams
    ): Partition {
      const valueSizeTooBig = (value): value is string[] =>
        angular.isArray(value) && value.length > PARAMS_LIMIT;

      return Object.keys(filterParams).reduce(
        (partition, columnName) => {
          const value = filterParams[columnName];

          if (valueSizeTooBig(value)) {
            partition.ext[columnName] = value;
          } else {
            partition.self[columnName] = value;
          }

          return partition;
        },
        { self: {}, ext: {} } as Partition
      );
    }

    private transformResults(sqlResultSet: SqlResultSet): Identified<T>[] {
      const data = [] as Identified<T>[];
      let i = 0;

      for (i = 0; i < sqlResultSet.rows.length; i++) {
        data[i] = this.unserializePayloadColumn(sqlResultSet, i);
      }
      return data;
    }

    private extractValues(
      filtersParameters: SanitizedFilterParams
    ): (string | string[] | BooleanInteger)[] {
      return Object.keys(filtersParameters)
        .map((key) =>
          this.filterValuesToSQLBindingsValues(filtersParameters[key])
        )
        .reduce(
          (acc, value) => acc.concat(value),
          [] as (string | string[] | BooleanInteger)[]
        ); // flatten array values
    }

    private applyDefaultOperator(
      filterKey: string,
      filterValue: SanitizedFilterParamsValue
    ): string {
      return Array.isArray(filterValue)
        ? filterKey + ' IN (' + this.slotsString(filterValue) + ')'
        : this.isRegExp(filterValue)
        ? filterKey + ' LIKE ?'
        : filterKey + '=?';
    }

    private unserializePayloadColumn(
      sqlResultSet: SqlResultSet,
      index: number
    ): Identified<T> {
      return angular.fromJson(
        (sqlResultSet.rows.item(index) as unknown & { payload: string }).payload
      );
    }

    private chunck(array: any[], size: number): any[][] {
      const len = array.length;
      const nbOfSlices = Math.ceil(len / size);

      const sliced = [] as any[][];
      let i = 0;
      let start = 0;
      let end = 0;

      for (; i < nbOfSlices; i++) {
        start = i * size;
        end = (i + 1) * size;
        sliced.push(array.slice(start, end));
      }

      return sliced;
    }

    private prepareRequestValues(resource, fields) {
      const entryDataFields = this.getFieldsData(resource, fields);

      return [angular.toJson(resource)].concat(entryDataFields);
    }

    private getFieldsData(resource: T, fields: string[]) {
      return fields.map((field) => {
        const nonBooleanValue = this.boolToInteger(resource[field]);

        return angular.isDefined(nonBooleanValue) ? nonBooleanValue : null;
      });
    }

    private getColumnsFromTable(tableName: string): IPromise<SqlResultSet> {
      const query = `PRAGMA table_info(${tableName});`;
      return this.execute(query);
    }

    /* Helpers */

    private joinFilterClauses(filterClauses: string[]): string {
      return filterClauses.join(' AND ');
    }

    private boolToInteger(value) {
      return this.isBoolean(value) ? (value ? 1 : 0) : value;
    }

    private slotsString(array: string[]): string {
      return array.map(() => '?').join(',');
    }

    private isRegExp(input): input is RegExp {
      return Object.prototype.toString.call(input) === '[object RegExp]';
    }

    private isBoolean(value): value is boolean {
      return typeof value === 'boolean';
    }
  }

  return (tableName: string, databaseFn: DatabaseFunction, options) =>
    new AngularSQLService(tableName, databaseFn, options);
}
