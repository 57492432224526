import { DraftReportsService } from './draft-reports.service';

export default angular
  .module('sfMobile.services.draft-reports', [
    'sfMobile.comments',
    'sfMobile.services.crud',
    'sfMobile.config.database',
    'sfMobile.services.dates',
    'sfMobile.services.pubsub',
    'sfMobile.services.debug',
  ])
  .service('draftReportsService', DraftReportsService).name;
