import { PopupService } from '../Popup/popup.service';

import {
  CapacitorBarcodeScanner,
  CapacitorBarcodeScannerScanResult,
  CapacitorBarcodeScannerTypeHintALLOption,
} from '@capacitor/barcode-scanner';

const BARCODE_MAX_LENGTH = 7048; // QR code maximum

export class BarcodeService {
  scanner = CapacitorBarcodeScanner;
  constructor(
    private popupService: PopupService,
    private $translate: ng.translate.ITranslateService
  ) {
    'ngInject';
  }

  scan(): ng.IPromise<string | false> {
    return this.scanner
      .scanBarcode({
        hint: CapacitorBarcodeScannerTypeHintALLOption.ALL,
      })
      .then(
        (barcodeResult: CapacitorBarcodeScannerScanResult) =>
          barcodeResult.ScanResult
      )
      .catch(() => false);
  }

  promptBarCode(): ng.IPromise<string> {
    const popup = this.popupService.prompt({
      title: this.$translate.instant('INPUT_BARCODE_TITLE'),
      placeholder: this.$translate.instant('INPUT_BARCODE_PLACHOLDER'),
      submit: this.$translate.instant('INPUT_BARCODE_SUBMIT'),
      required: false,
    });

    return popup.promptPromise;
  }

  validateBarcode(code: string): boolean {
    return code.length < BARCODE_MAX_LENGTH;
  }
}
