export default function placesRouteConfig($stateProvider) {
  'ngInject';

  // Routes.
  $stateProvider
    .state('index.menu-more.reactive-campaigns', {
      url: '/reactive-campaigns',
      abstract: true,
      template: '<ion-nav-view></ion-nav-view>',
    })
    .state('index.menu-more.reactive-campaigns.activity-list', {
      url: '/activity',
      template: `
        <ion-view>
          <sf-reactive-campaigns
            hide-actions="true"
            is-activity="true"
            on-campaign-item-click="$ctrl.onCampaignClick(campaign)"
          >
          </sf-reactive-campaigns>
        </ion-view>
      `,
      controller: CampaignsActivityController,
      controllerAs: '$ctrl',
      analyticsName: 'Reactive campaigns activity list',
    })
    .state('index.menu-more.reactive-campaigns.list', {
      url: '/list',
      params: {
        shouldReset: false,
      },
      template: `
        <ion-view>
          <sf-reactive-campaigns
            on-campaign-item-click="$ctrl.onCampaignClick(campaign)"
            filter-respondents="true"
          >
          </sf-reactive-campaigns>
        </ion-view>
      `,
      controller: CampaignsListController,
      controllerAs: '$ctrl',
      analyticsName: 'Reactive campaigns List',
    })
    .state('index.menu-more.reactive-campaigns.reports', {
      url: '/reports',
      params: {
        placeId: null,
      },
      template: `
        <ion-view cache-view="false">
          <sf-view sf-tab-bar-hide="true">
            <sf-nav-bar
              title="{{ ::'REACTIVE_CAMPAIGNS_REPORTS_TITLE' | translate }}"
              display-back-button="true"
            >
            </sf-nav-bar>
            <sf-content class="sf_view__content"
              key="reportsContent">
              <sf-content-header>
                <h1>{{ ::'REACTIVE_CAMPAIGNS_REPORTS_TITLE' | translate }}</h1>
              </sf-content-header>
              <sf-reactive-campaign-reports
                content-key="reportsContent"
              ></sf-reactive-campaign-reports>
            </sf-content>
          </sf-view>
        </ion-view>
      `,
      controllerAs: '$ctrl',
      analyticsName: 'Reactive campaign reports',
    })
    .state('index.menu-more.reactive-campaigns.details', {
      url: '/:campaignId?placeId&referer',
      params: {
        referer: null,
        placeId: null,
      },
      template: `
        <ion-view cache-view="false">
          <sf-reactive-campaign-details campaign="$ctrl.campaign" profile="$ctrl.profile">
          </sf-reactive-campaign-details>
        </ion-view>
      `,
      controllerAs: '$ctrl',
      analyticsName: 'Reactive campaign details',
      controller: ReactiveCampaignDetailsViewController,
      resolve: {
        rsvpCampaign: getRsvpCampaign,
        rsvpProfile: getRsvpUser,
        referer: getReferer,
      },
    })
    .state('index.menu-more.reactive-campaigns.activity-details', {
      url: '/activity/:campaignId',
      params: {
        referer: null,
        openPreviewWithAnswerId: null,
        targetTab: null,
      },
      template: `
        <ion-view cache-view="false">
          <sf-reactive-campaign-activity-details campaign="$ctrl.campaign">
          </sf-reactive-campaign-activity-details>
        </ion-view>
      `,
      controllerAs: '$ctrl',
      analyticsName: 'Reactive campaign activity details',
      controller: ReactiveCampaignDetailsViewController,
      resolve: {
        rsvpCampaign: getRsvpCampaign,
        referer: getReferer,
      },
    })
    .state('index.menu-more.reactive-campaigns.form', {
      url: '/form/:reportId?questionId&online&viewRestored&campaignId&locationId&paramValueId&shouldRedirectToFinalize&answers&hasDraftReports',
      params: {
        referer: null,
      },
      template: `
        <ion-view class="sf_form"
          cache-view="false"
          can-swipe-back="false">

          <sf-form
            report="$ctrl.report"
            campaign="$ctrl.campaign"
            place="$ctrl.place"
            user="$ctrl.user"
            referer="$ctrl.referer"
          >
          </sf-form>

        </ion-view>
      `,
      controller: CampaignFormViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Campaign Form',
      resolve: {
        referer: getReferer,
        rsvpCampaign: getRsvpCampaign,
        rsvpCurReport: getRsvpCurReport,
        rsvpPlace: getRsvpPlace,
        rsvpReport: getRsvpReport,
        rsvpParam: getRsvpParam,
      },
    })
    .state('index.menu-more.reactive-campaigns.report', {
      url: '/{campaignId:[0-9a-fA-F]{24}}/report/{reportId:[0-9a-fA-F]{24}}?online',
      params: {
        referer: null,
        targetTab: null,
      },
      template: `
        <ion-view class="sf_report">
          <sf-view sf-tab-bar-hide="true">
            <sf-nav-bar
              display-back-button="true"
              fallback-back-link="index.activity.details">

                <div class="buttons buttons-right">
                  <button
                    ng-if="$ctrl.isReportEditable"
                    class="button button-clear button-small button-light button-header"
                    ui-sref="index.menu-more.reactive-campaigns.form({
                      campaignId: $ctrl.report.contents.campaign_id,
                      reportId: $ctrl.report._id,
                      online: $ctrl.online,
                      referer: $ctrl.referer,
                    })">
                    {{ ::'REPORT_INFOS_EDIT_LINK' | translate }}
                  </button>
                </div>
              </sf-nav-bar>
              <sf-report
                campaign="$ctrl.campaign"
                report="$ctrl.report"
                profile="$ctrl.profile"
                place="$ctrl.place"
                target-tab="$ctrl.targetTab">
              </sf-report>
            </sf-view>
          </ion-view>
        `,
      resolve: {
        rsvpReport: getReportPageRsvpReport,
        rsvpCampaign: getRsvpCampaign,
        rsvpPlace: getReportRsvpPlace,
        referer: getReferer,
      },
      controller: ReportInfosViewController,
      controllerAs: '$ctrl',
      analyticsName: 'Report',
      cache: false,
    });

  // @ngInject
  function ReactiveCampaignDetailsViewController(rsvpCampaign, rsvpProfile) {
    this.campaign = rsvpCampaign;
    this.profile = rsvpProfile;
  }

  // @ngInject
  // eslint-disable-next-line max-params
  function CampaignFormViewController(
    rsvpCampaign,
    rsvpReport,
    rsvpPlace,
    rsvpProfile,
    referer
  ) {
    this.report = rsvpReport;
    this.place = rsvpPlace;
    this.user = rsvpProfile;
    this.campaign = rsvpCampaign;
    // Referer is an ui-state name where the user should be redirected after an edit of the report
    this.referer = referer;
  }

  // @ngInject
  // eslint-disable-next-line max-params
  function ReportInfosViewController(
    $rootScope,
    $stateParams,
    rsvpCampaign,
    rsvpReport,
    rsvpProfile,
    rsvpPlace,
    referer,
    reportValidationService,
    campaignsUtilsService
  ) {
    this.online = !!$stateParams.online;
    this.campaign = rsvpCampaign;
    this.report = rsvpReport;
    this.profile = rsvpProfile;
    this.place = rsvpPlace;
    this.targetTab = $stateParams.targetTab;
    // Referer is an ui-state name where the user should be redirected after an edit of the report
    this.referer = referer;
    this.goBack = $rootScope.$ionicGoBack;
    this.isReportEditable = false;

    if (this.campaign.contents.preferences) {
      this.isReportEditable = campaignsUtilsService.canEditReport(
        rsvpReport,
        rsvpCampaign,
        rsvpProfile
      );
    }

    if (
      reportValidationService.isRevision(this.report) &&
      this.report.contents.user_id === this.profile._id
    ) {
      this.isReportEditable = true;
    }

    const allowEditPendingValidationProp =
      this.campaign.contents.preferences.find(
        (prop) => prop.name === 'allowEditPendingValidation'
      );
    const allowEditPendingValidation = allowEditPendingValidationProp
      ? allowEditPendingValidationProp.value
      : true;
    if (
      reportValidationService.isPendingValidation(this.report) &&
      !allowEditPendingValidation
    ) {
      this.isReportEditable = false;
    }

    const allowEditValidatedProp = this.campaign.contents.preferences.find(
      (prop) => prop.name === 'allowEditValidated'
    );
    const allowEditValidated = allowEditValidatedProp
      ? allowEditValidatedProp.value
      : true;
    if (
      reportValidationService.isValidated(this.report) &&
      !allowEditValidated
    ) {
      this.isReportEditable = false;
    }
  }

  // @ngInject
  function getRsvpCampaign($stateParams, campaignsService) {
    return campaignsService.getOne($stateParams.campaignId);
  }

  // @ngInject
  function getRsvpCurReport($stateParams, reportsService, draftReportsService) {
    if ($stateParams.hasDraftReports === 'true') {
      return draftReportsService.getOne($stateParams.reportId);
    }

    return $stateParams.online === 'true'
      ? reportsService.crud.getRemote($stateParams.reportId, {})
      : getReport($stateParams.reportId);

    function getReport(id) {
      return reportsService.crud
        .queryLocal({ _id: id })
        .then((reports) => (reports.length ? reports[0] : null))
        .catch(() => null);
    }
  }

  // @ngInject
  // eslint-disable-next-line max-params
  function getRsvpReport(
    $stateParams,
    campaignsUtilsService,
    rsvpCampaign,
    rsvpCurReport,
    rsvpProfile,
    rsvpParam
  ) {
    let posAudit;

    if (rsvpParam) {
      const param = rsvpParam.values.find(
        ({ id }) => id === $stateParams.paramValueId
      );
      posAudit = {
        param_id: rsvpCampaign.contents.posAuditParam_id,
        paramLabel: rsvpParam.label,
        value: param.value,
        value_id: $stateParams.paramValueId,
      };
    }

    return (
      rsvpCurReport ||
      campaignsUtilsService.initReport(
        $stateParams.reportId,
        rsvpCampaign,
        rsvpProfile,
        $stateParams.locationId,
        posAudit,
        $stateParams.answers
      )
    );
  }

  // @ngInject
  function getRsvpParam($q, $stateParams, rsvpCampaign, placesParamsService) {
    if ($stateParams.paramValueId && rsvpCampaign.contents.posAuditParam_id) {
      return placesParamsService.getParamById(
        rsvpCampaign.contents.posAuditParam_id
      );
    }

    return $q.when(undefined);
  }

  // @ngInject
  function getReportPageRsvpReport(rsvpProfile, $stateParams, reportsService) {
    return $stateParams.online === 'true'
      ? reportsService.crud.getRemote($stateParams.reportId, {
          mode: 'expanded',
        })
      : reportsService.crud.get($stateParams.reportId);
  }

  // @ngInject
  function getReportRsvpPlace(rsvpProfile, $q, rsvpReport, placesService) {
    const placeId = rsvpReport.contents.place_id;

    return placeId
      ? placesService
          .queryLocal({ _id: placeId })
          .then((places) =>
            places.length
              ? places[0]
              : placesService.getRemote(placeId, {}.undef, true)
          )
      : $q.when(null);
  }
  // @ngInject
  function getReferer($stateParams) {
    return $stateParams.referer;
  }

  function getRsvpUser(rsvpProfile, $q, $stateParams, usersService) {
    'ngInject';
    const { asUser } = $stateParams;

    return asUser ? usersService.getUser(asUser) : $q.when(rsvpProfile);
  }

  // @ngInject
  // eslint-disable-next-line max-params
  function getRsvpPlace(
    $q,
    $stateParams,
    $translate,
    placesService,
    popupService,
    rsvpCurReport
  ) {
    const placeId = getPlaceId($stateParams, rsvpCurReport);
    const getPlace = (placeId) => {
      return placesService.get(placeId).catch((err) => {
        popupService.showError({
          title: $translate.instant('FORM_PLACE_UNAVAILABLE_ERROR_TITLE'),
          desc: $translate.instant('FORM_PLACE_UNAVAILABLE_ERROR_DESC'),
        });
        throw err;
      });
    };

    return placeId ? getPlace(placeId) : $q.when(null);
  }

  function getPlaceId(params, report) {
    return params.locationId || (report && report.contents.place_id);
  }

  // @ngInject
  function CampaignsListController($state) {
    this.onCampaignClick = (campaign) => {
      return $state.go('index.menu-more.reactive-campaigns.details', {
        campaignId: campaign._id,
      });
    };
  }

  // @ngInject
  function CampaignsActivityController($state) {
    this.onCampaignClick = (campaign) => {
      return $state.go('index.menu-more.reactive-campaigns.activity-details', {
        campaignId: campaign._id,
      });
    };
  }
}
