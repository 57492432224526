import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import { PlatformService } from '../Platform';

export class TrackingTransparencyService {
  AppTrackingTransparency = AppTrackingTransparency;

  constructor(
    private $q: ng.IQService,
    private platformService: PlatformService
  ) {
    'ngInject';
  }
  getAuthorizationPromise(): ng.IPromise<AppTrackingStatusResponse> {
    if (this.platformService.isiOS() && this.AppTrackingTransparency) {
      return this.$q.resolve(
        this.AppTrackingTransparency.getStatus().then((response) => {
          if (response.status === 'notDetermined') {
            return this.AppTrackingTransparency.requestPermission().then((permission) => {
              if (permission.status === 'denied') {
                return this.$q.resolve<AppTrackingStatusResponse>({
                  status: 'authorized',
                });
              }
              return permission;
            });
          }
          // If response is denied no need to block the support button
          if (response.status === 'denied') {
            return this.$q.resolve<AppTrackingStatusResponse>({
              status: 'authorized',
            });
          }
          return response;
        })
      );
    }

    return this.$q.resolve<AppTrackingStatusResponse>({
      status: 'authorized',
    });
  }
}
