import { APIStore } from '@places/index';
import { DataAccessLayerService } from '@services/Utils/CRUD/crud-service';
import { CrudFactory } from '@services/Utils/CRUD/crud-service.factory';
import { DateService } from '@services/Utils/Dates/date.service';
import { DebugService } from '@services/Utils/Debug/debug.service';
import { IDeferred, IPromise } from 'angular';
import { IAPIList, ObjectId, User } from 'app';
import { differenceWith, isNil } from 'ramda';
import { CommentsResourceType } from '../../../comments/services/comments-api/comments-api.factory';
import {
  CommentsFactory,
  CommentsService,
} from '../../../comments/services/comments/comments.factory';
import { DATABASE_SCHEMA } from '../../../core/database.config';
import { Campaign, CampaignForm } from '../campaigns/campaigns';
import { Answer, Report } from '../reports/reports';
import { DraftReport } from './draft-reports';
import { getHashedReport } from './draft-reports-validation.service';

type LocalDraftParams = { campaign_id?: string; place_id?: string };
type RemoteDraftParams = { campaignId?: string; placeId?: string };

export class DraftReportsService {
  commentsService: CommentsService;
  crud: DataAccessLayerService<DraftReport>;
  basePath = '/draft-reports';

  constructor(
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private commentsFactory: CommentsFactory,
    private crudFactory: CrudFactory<DraftReport>,
    private databaseSchema: typeof DATABASE_SCHEMA,
    private debugService: DebugService,
    private dateService: DateService
  ) {
    'ngInject';
    const tableConfig = this.databaseSchema.tables.draft_reports;

    this.commentsService = this.commentsFactory(
      CommentsResourceType.DRAFT_REPORTS
    );
    this.crud = this.crudFactory(tableConfig.table_name, {
      take_care_of_user_profile: false,
      exclude_offline_params: ['states', 'date_field', 'start_date'],
      backup: {
        indexed_fields: tableConfig.indexed_fields.map((field) => field.name),
      },
      path_name: 'draft-reports',
    });
  }

  buildLocalParams(campaignId?: string, placeId?: string): LocalDraftParams {
    return {
      campaign_id: campaignId,
      ...(placeId ? { place_id: placeId } : {}),
    };
  }

  createOne(
    report: Report,
    context: {
      form: { id: string; contents: CampaignForm };
      place: APIStore;
      checklist: Campaign;
      user: User;
    },
    localOnly = false
  ): IPromise<DraftReport> {
    const reportToSend = this.prepareData(report);

    return getHashedReport(reportToSend, context).then((hashedReport) => {
      this.debugService.log('Sending report after preparation', {
        reportId: report.id,
        report: JSON.stringify(report),
        reportToSend: JSON.stringify(reportToSend),
        formId: context.form.id,
      });

      if (localOnly) {
        return this.crud.saveLocal(report.id, hashedReport);
      }

      return this.crud.saveRemote(report.id, hashedReport, { pov: 'user' });
    });
  }

  getOne(reportId: ObjectId): IPromise<DraftReport> {
    return this.crud.get(reportId, {});
  }

  /**
   * Return the list of all draft reports within given parameters
   * Filter out local drafts older than their remote copy
   */
  getAll(
    params: RemoteDraftParams = {},
    errorOnInsufficientNetwork = true
  ): IPromise<DraftReport[]> {
    return this.$q
      .all([
        this.getAllRemote(params).catch((error) => {
          if (errorOnInsufficientNetwork) {
            throw error;
          }

          return {
            entries: [],
            count: 0,
          };
        }),
        this.getAllLocal(
          this.buildLocalParams(params.campaignId, params.placeId)
        ),
      ])
      .then(([remoteList, locals]) => {
        const { entries: remotes } = remoteList;

        const comparedWithLocalsDrafts = remotes.map((remote) => {
          const moreRecentLocal = locals.find((local) => {
            return (
              local._id === remote._id &&
              this.dateService.isGreaterThan(
                local.modified_date,
                remote.modified_date
              )
            );
          });

          if (!isNil(moreRecentLocal)) {
            return moreRecentLocal;
          }

          return remote;
        });

        const othersLocalDrafts = differenceWith(
          ({ _id: localId }, { _id: remoteId }) => localId === remoteId,
          locals,
          comparedWithLocalsDrafts
        );

        return [...comparedWithLocalsDrafts, ...othersLocalDrafts];
      });
  }

  getAllRemote(
    params: RemoteDraftParams = {}
  ): IPromise<IAPIList<DraftReport>> {
    return this.crud.simpleApiList(this.basePath, params, false, {
      pov: 'user',
    });
  }

  getAllLocal(params: LocalDraftParams = {}): IPromise<DraftReport[]> {
    return this.crud.queryLocal(params);
  }

  deleteOne(reportId: ObjectId): IPromise<void> {
    return this.crud.delete(reportId, { pov: 'user' });
  }

  deleteLocally(id: ObjectId): void {
    this.crud.dataStore.deleteLocal(id);
  }

  updateOne(
    report: DraftReport,
    context: {
      form: { id: string; contents: CampaignForm };
      place: APIStore;
      checklist: Campaign;
      user: User;
    }
  ): IPromise<DraftReport> {
    const reportToSend = this.prepareData(report);

    return getHashedReport(reportToSend, context).then((hashedReport) => {
      return this.crud.save(report._id, hashedReport, { pov: 'user' });
    });
  }

  compareAnswers(prevAnswers: Answer[], currAnswers: Answer[]): boolean {
    return currAnswers.every((currentAnswer) => {
      const previousAnswer: Answer | undefined = prevAnswers.find(
        (previousAnswer) => {
          return previousAnswer._id === currentAnswer._id;
        }
      );

      if (isNil(previousAnswer)) {
        return false;
      } else {
        return currentAnswer.values.every((currentValue) => {
          const previousValue = previousAnswer.values.find((previousValue) => {
            return previousValue.field_id === currentValue.field_id;
          });

          if (!previousValue) {
            return false;
          } else {
            return currentValue.value === previousValue.value;
          }
        });
      }
    });
  }

  prepareData(data) {
    delete data.contents.form;
    delete data.contents.place;

    return data;
  }

  validate(
    reportId: ObjectId,
    params: { canceler?: IDeferred<unknown> }
  ): IPromise<void> {
    const timeoutDefault = 10000;
    const requestConfig = {
      timeout: params?.canceler ? params.canceler.promise : timeoutDefault,
    };

    return this.crud
      .useUserPOV(`${this.basePath}/${reportId}/validate`, false, {
        pov: 'user',
      })
      .then((uri) => this.$http.post<void>(uri, {}, requestConfig))
      .then((res) => res.data);
  }
}
