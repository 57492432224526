import type { PlatformService } from '../Platform';
import type { WebIntercomBridge } from './web-intercom-bridge';
import { Intercom } from '@capacitor-community/intercom';
import { AppTrackingStatusResponse } from 'capacitor-plugin-app-tracking-transparency';
import type { User } from '../../..';
import { TrackingTransparencyService } from '../TrackingTransparencyService/trackingTransparency.service';
import { ConfigService } from '../Config/config.service';

const ENV_DEV = 'development';

export class IntercomService {
  private initPromise: ng.IPromise<any>;
  Intercom = Intercom;
  private isIntercomAvailable: boolean;

  // eslint-disable-next-line max-params
  constructor(
    private $q: ng.IQService,
    private $log: ng.ILogService,
    private platformService: PlatformService,
    private ConfigServer,
    private webIntercomBridge: WebIntercomBridge,
    private trackingTransparency: TrackingTransparencyService,
    private configService: ConfigService
  ) {
    'ngInject';

    this.isIntercomAvailable = !this.configService.isChinaBuild();

    if (platformService.isBrowser() && ConfigServer.ENV !== ENV_DEV) {
      //Try to load intercom widget as soon as possible
      this.initPromise = this.webIntercomBridge.loadScript();
      return;
    }

    this.initPromise = this.$q.resolve();
  }

  init(): ng.IPromise<void> {
    return this.initPromise.then(() => {
      if (!this.platformService.isBrowser() || !this.isIntercomAvailable) {
        return;
      }
      const settings: Intercom_.IntercomSettings = {
        app_id: this.ConfigServer.INTERCOM_APP_ID,
        hide_default_launcher: true,
      };

      this.webIntercomBridge.boot(settings);
    });
  }

  registerUser(user: User): ng.IPromise<void> {
    if (!this.isIntercomAvailable) {
      return this.$q.resolve();
    }

    return this.initPromise
      .then(() => {
        return this.trackingTransparency.getAuthorizationPromise();
      })
      .then((authorization: AppTrackingStatusResponse | null) => {
        if (authorization?.status === 'authorized') {
          return user;
        }

        return null;
      })
      .then((userToRegister) => {
        if (!userToRegister) {
          this.$log.error('Intercom: User informations needed');
          return;
        }
        if (this.platformService.isBrowser()) {
          this.webIntercomBridge.boot({
            user_id: userToRegister._id,
            user_hash: userToRegister.user_hash,
          });
          return;
        }
        this.Intercom.registerIdentifiedUser({
          userId: userToRegister._id,
        });
      });
  }

  sendEvent(name: string, data: Record<string, unknown>): ng.IPromise<void> {
    if (!this.isIntercomAvailable) {
      return this.$q.resolve();
    }
    return this.initPromise
      .then(() => {
        if (this.platformService.isBrowser()) {
          this.webIntercomBridge.logEvent(name, data);
          return null;
        }
        return this.trackingTransparency.getAuthorizationPromise();
      })
      .then((authorization: AppTrackingStatusResponse | null) => {
        if (authorization?.status === 'authorized') {
          this.Intercom.logEvent({ name, data });
        }
      });
  }

  displayConversations(): ng.IPromise<void> {
    if (!this.isIntercomAvailable) {
      return this.$q.resolve();
    }
    return this.initPromise
      .then(() => {
        if (this.platformService.isBrowser()) {
          this.webIntercomBridge.showMessages();
          return null;
        }
        return this.trackingTransparency.getAuthorizationPromise();
      })
      .then((authorization: AppTrackingStatusResponse | null) => {
        if (authorization?.status === 'authorized') {
          this.Intercom.displayMessenger();
        }
      });
  }

  reset(): ng.IPromise<void> {
    if (!this.isIntercomAvailable) {
      return this.$q.resolve();
    }
    return this.initPromise.then(() => {
      if (this.platformService.isBrowser()) {
        this.webIntercomBridge.shutdown();
        return;
      }
      this.Intercom.logout();
    });
  }
}
