const REPORT_VIEW_ANALYTICS = {
  reportContent: 'Report Content',
  reportComments: 'Report Comments',
  reportStats: 'Report Stats',
  reportTasks: 'Report Tasks',
};

const COMMENTS_TAB_INDEX = 2;
const COMMENTS_TAB_NAME = 'comments';

export const ReportComponent = {
  bindings: {
    campaign: '<',
    form: '<',
    report: '<',
    profile: '<',
    place: '<',
    targetTab: '<?',
  },
  templateUrl: 'reports/report/report.html',
  controller: class ReportInfosController {
    // eslint-disable-next-line max-params
    constructor(
      $q,
      pubSubService,
      tabsService,
      tasksService,
      reportsService,
      modalService,
      formsService,
      dateFormatService,
      reportScoringService,
      segmentService,
      TASK_STATUSES,
      campaignsUtilsService,
      reportValidationService
    ) {
      'ngInject';
      Object.assign(this, {
        $q,
        pubSubService,
        tabsService,
        tasksService,
        reportsService,
        modalService,
        formsService,
        dateFormatService,
        reportScoringService,
        segmentService,
        TASK_STATUSES,
        campaignsUtilsService,
        reportValidationService,
      });

      this.getDateFormatted = this.dateFormatService.getDateAndTimeFormatted;
    }

    $onInit() {
      if (this.campaign) {
        this.useNewCampaigns = true;
        this.form = this.campaignsUtilsService.getFormFromCampaign(
          this.campaign
        );
      }
      const reportUsers = this.report.users || {};

      this.hasTasksFeature = this.tasksService.hasFeatureFlag();

      this.isSend = this.report.localStatus !== 'ready';
      this.user = { contents: reportUsers[this.report.contents.user_id] };
      this.owner = reportUsers[this.report.contents.owner_id];

      this.isScoreActivated = this.reportScoringService.scoreIsActivate(
        this.form,
        this.report
      );
      this.isStatusEditable = this.reportValidationService.isStatusEditable(
        this.form
      );

      this.reportsRequestFilter = [
        {
          name: 'report_id',
          value: this.report._id,
        },
      ];

      this.unsubscribeEvent = this.pubSubService.subscribe(
        'TASK_STATUS_CHANGED',
        this.showToasterOnStatusChange.bind(this)
      );

      return this.$q.all([this.getCommentsDelta(), this.getTasksCounts()]);
    }

    $postLink() {
      if (this.targetTab === COMMENTS_TAB_NAME) {
        this.selectTab(COMMENTS_TAB_INDEX);
      }
    }

    $onDestroy() {
      this.unsubscribeEvent();
    }

    getCommentsDelta() {
      return this.reportsService.commentsService
        .list(this.report._id)
        .then(({ entries }) => {
          this.nbReportComments = entries.length;
        });
    }

    getTasksCounts() {
      this.tasksService.getTasksCount(this.reportsRequestFilter).then((res) => {
        this.nbTasks = res.reduce(
          (count, taskCount) => count + taskCount.total,
          0
        );
      });
    }

    selectTab(tabIndex) {
      this.tabsService.select('reportTabs', tabIndex);
    }

    changeStatus() {
      const template = `
        <sf-status-selector
          current-status="$ctrl.currentStatus"
          status="$ctrl.status"
          on-close="$ctrl.onClose()"
          on-save="$ctrl.onSave()"
          translations="$ctrl.translations">
        </sf-status-selector>
      `;
      const bindings = {
        status: this.formsService.getAvailableStatus(this.form),
        currentStatus: this.report.contents.state,
        translations: this.form && this.form.i18n ? this.form.i18n : {}.undef,
      };
      const options = {
        animation: 'slide-in-top',
      };

      this.pendingStatus = false;

      return this.modalService
        .openAsPromise(template, bindings, options)
        .then((status) =>
          this.saveReportStatus(status).then((report) => {
            this.report = report;

            this.pubSubService.publish('REPORT_STATE_UPDATED', {
              report_id: this.report._id,
              state: status.key,
            });
            return report;
          })
        );
    }

    saveReportStatus(status) {
      const report = {
        _id: this.report._id,
        saved_date: new Date(),
        contents: { state: status.key },
      };
      const promise = !this.report.localStatus
        ? this.reportsService.crud.save(this.form._id, report._id, report)
        : this.saveLocally(status.key);

      this.pendingStatus = true;

      return promise
        .then((reportSaved) => {
          this.report.contents.state = reportSaved.contents.state;
          return reportSaved;
        })
        .finally(() => {
          this.pendingStatus = false;
        });
    }

    saveLocally(state) {
      let reportCopy = { ...this.report };

      reportCopy.contents.state = state;
      return this.reportsService.crud.saveLocal(reportCopy._id, reportCopy);
    }

    onTabClick(tabName) {
      this.currentStickyHandle = tabName;

      if (REPORT_VIEW_ANALYTICS[tabName]) {
        this.segmentService.page(REPORT_VIEW_ANALYTICS[tabName]);
      }
    }

    onTasksTabClick() {
      this.onTabClick('reportTasks');
      if (this.reloadTasks) {
        this.reloadTasks();
      }
    }

    showScore() {
      return this.isScoreActivated;
    }

    showToasterOnStatusChange({ task }) {
      const { status } = task.contents;

      this.toasterMessageKey =
        status === this.TASK_STATUSES.TODO
          ? 'TASKS_STATUS_CHANGED_TOASTER_TO_DO'
          : 'TASKS_STATUS_CHANGED_TOASTER_DONE';
      this.showToaster();
    }
  },
};
