import migration10Config from './10';
import migration11Config from './11';
import migration13Config from './13';
import migration14Config from './14';
import migration15Config from './15';
import migration16Config from './16';
import migration17Config from './17';
import migration18Config from './18';
import migration19Config from './19';
import migration20Config from './20';
import migration40Config from './4.0';
import migration42Config from './4.2';
import migration43Config from './4.3';
import migration44Config from './4.4';
import migration45Config from './4.5';
import migration46Config from './4.6';
import migration47Config from './4.7';
import migration48Config from './4.8';
import migration49Config from './4.9';
import migration50Config from './5.0';
import migration6Config from './6';
import migration7Config from './7';
import migration8Config from './8';
import migration9Config from './9';

export default angular
  .module('sfMobile.core.migrations', ['sf.sqlStorage'])
  .config(migration40Config)
  .config(migration42Config)
  .config(migration43Config)
  .config(migration44Config)
  .config(migration45Config)
  .config(migration46Config)
  .config(migration47Config)
  .config(migration48Config)
  .config(migration49Config)
  .config(migration50Config)
  .config(migration6Config)
  .config(migration7Config)
  .config(migration8Config)
  .config(migration9Config)
  .config(migration10Config)
  .config(migration11Config)
  .config(migration13Config)
  .config(migration14Config)
  .config(migration15Config)
  .config(migration16Config)
  .config(migration17Config)
  .config(migration18Config)
  .config(migration19Config)
  .config(migration20Config).name;
