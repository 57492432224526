import { last } from 'ramda';

// eslint-disable-next-line max-params
export function FormService(
  $q,
  reportsService,
  ReportSending,
  modalService,
  objectIdService,
  dateService,
  draftReportsService
) {
  'ngInject';

  const methods = {
    initReport,
    saveBackup,
    deleteBackup,
    _savePromise: null,
    getQuestionChoices,
    getQuestionCondition,
    getSectionIndexByQuestion,
    openReportScoringModal,
    buildNodesForPreviousAnswers,
    isTodayInDueDateRange,
    saveDraft,
  };

  function initReport(reportId, datas) {
    const form = datas.form;
    const profile = datas.profile;
    const place = datas.place;
    const mission = datas.mission;
    const report = reportsService.getNewReport(reportId, form, profile);

    report.contents.type = 'share';

    if (mission) {
      if (mission.contents.place_id) {
        report.contents.place_id = mission.contents.place_id;
      }
      report.contents.type = 'mission';
      report.contents.brief = mission.contents.brief;
      report.contents.owner_id = mission.contents.owner_id;
    } else if (place) {
      report.contents.place_id = place._id;
    }

    return report;
  }

  /**
   * Save datas
   * @param  {Object}  ctx               - Context with report, user and place objects
   * @param  {Object}  form              - Form datas
   * @param  {Boolean} hasToCleanReport  - If needed to remove old datas
   * @return {Promise}      - Saved of report
   */
  function saveBackup(
    ctx,
    form,
    hasToCleanReport,
    hasToRemoveUnconfirmedPrefilled,
    shouldSaveDraftsInBackend = false
  ) {
    const removeUnconfirmedPrefilled = (report, unconfirmedIds) =>
      report.contents.answers.filter(
        (answer) => !(unconfirmedIds || []).includes(answer.question_id)
      );

    if (hasToRemoveUnconfirmedPrefilled) {
      ctx.report.contents.answers = removeUnconfirmedPrefilled(
        ctx.report,
        ctx.unconfirmedPrefilledQuestionsIds
      );
    }

    const wasToBeSent = ctx.report.localStatus === 'toBeSent';

    const reportWithSavedDate = wasToBeSent
      ? ctx.report
      : Object.assign(ctx.report, {
          saved_date: new Date(),
        });
    const cleanReport = () => {
      const reportSending = new ReportSending(form, ctx);

      return reportSending.reportCleaned;
    };
    const reportUpdated = hasToCleanReport
      ? cleanReport()
      : reportWithSavedDate;

    const cachePromise = methods._savePromise || $q.when();

    return cachePromise
      .then(() => {
        if (
          shouldSaveDraftsInBackend &&
          !reportUpdated.validationState &&
          !wasToBeSent
        ) {
          methods._savePromise = draftReportsService.crud.saveLocal(
            reportUpdated._id,
            reportUpdated
          );
        } else {
          methods._savePromise = reportsService.crud.saveLocal(
            reportUpdated._id,
            reportUpdated
          );
        }

        return methods._savePromise;
      })
      .finally(() => {
        methods._savePromise = null;
      });
  }

  /**
   * Save datas
   * @param  {Object}  ctx               - Context with report, user and place objects
   * @param  {Object}  form              - Form datas
   * @param  {Boolean} hasToCleanReport  - If needed to remove old datas
   * @return {Promise}      - Saved of report
   */
  function saveDraft(
    ctx,
    form,
    hasToCleanReport,
    hasToRemoveUnconfirmedPrefilled
  ) {
    const removeUnconfirmedPrefilled = (report, unconfirmedIds) =>
      report.contents.answers.filter(
        (answer) => !(unconfirmedIds || []).includes(answer.question_id)
      );

    if (hasToRemoveUnconfirmedPrefilled) {
      ctx.report.contents.answers = removeUnconfirmedPrefilled(
        ctx.report,
        ctx.unconfirmedPrefilledQuestionsIds
      );
    }

    const wasToBeSent = ctx.report.localStatus === 'toBeSent';

    if (!wasToBeSent) {
      ctx.report.saved_date = new Date();
    }

    const reportSending = new ReportSending(form, ctx);

    if (hasToCleanReport) {
      reportSending.cleanReport();
    }

    const cachePromise = methods._savePromise || $q.when();

    return cachePromise
      .then(() => reportSending.sendDraft())
      .finally(() => {
        methods._savePromise = null;
      });
  }

  /**
   * Delete report backup
   * @param  {Object}  reportId  - The id of the report to delete
   * @return {Promise} Promise of the deletion success
   */
  function deleteBackup(reportId, shouldSaveDraftsInBackend) {
    return shouldSaveDraftsInBackend
      ? draftReportsService.deleteLocally(reportId)
      : reportsService.deleteLocally(reportId);
  }

  function getQuestionChoices(question) {
    return (
      (Array.isArray(question.fields) &&
        question.fields.length > 0 &&
        question.fields[0].set) ||
      []
    );
  }
  function getQuestionCondition(node) {
    const [condition] = node.metadata.conditions || [];

    if (!condition) {
      return null;
    }
    if (condition.mode === 'store') {
      return {
        name: condition.item,
        value: condition.value,
      };
    }

    return {
      name: condition.targetRef.data.title,
      value: condition.value,
    };
  }
  function getSectionIndexByQuestion(form, questionId) {
    const { sections, questions } = form.contents;

    const question = questions.find((q) => q._id === questionId);

    if (!question) {
      return 0;
    }
    const sectionId = question.section_id;
    const sectionsPages = sections.reduce((output, section) => {
      let index = output.length ? last(output).index : -1;

      index += section.level === 0 ? 1 : 0;
      return [...output, { _id: section._id, index: index }];
    }, []);
    const currentSection = sectionsPages.find((s) => s._id === sectionId);

    return currentSection ? currentSection.index : 0;
  }

  function openReportScoringModal(bindings) {
    const template = `
      <sf-form-details-score
        form="$ctrl.form"
        campaign="$ctrl.campaign"
        on-close="$ctrl.onClose()"
        on-save="$ctrl.onSave()"
        place="$ctrl.place"
        report="$ctrl.report">
      </sf-form-details-score>`;

    modalService.openAsPromise(template, bindings);
  }

  function buildNodesForPreviousAnswers(form, report, previousAnswers) {
    if (
      !previousAnswers ||
      !previousAnswers.answers ||
      !previousAnswers.answers.length
    ) {
      return {
        nodes: report.contents.nodes,
        previousAnswers: [],
      };
    }

    let nodes = [];
    let parentNode = {};

    form.contents.sections.forEach((section) => {
      let node = report.contents.nodes.filter(
        (n) => n.sections_ids[0] === section._id
      )[0];

      if (section.level === 0) {
        parentNode = {};
      }

      node = {
        _id: objectIdService.create(),
        sections_ids: [section._id].concat(parentNode.sections_ids || []),
        parents_ids: [].concat(parentNode._id || []),
        ...node,
      };

      previousAnswers.answers = previousAnswers.answers.map((answer) =>
        answer.sections_ids[0] === section._id
          ? { _id: objectIdService.create(), nodes_ids: [node._id], ...answer }
          : answer
      );

      if (section.level === 0) {
        parentNode = node;
      }

      nodes = nodes.concat(node);
    });

    return {
      nodes,
      previousAnswers: previousAnswers.answers,
    };
  }

  function isTodayInDueDateRange(form) {
    if (form.contents.end_date) {
      if (form.contents.start_date) {
        return dateService.isTodayInRange(
          form.contents.start_date,
          form.contents.end_date
        );
      }
      return !dateService.isOverdue(form.contents.end_date);
    }

    return false;
  }

  return methods;
}
