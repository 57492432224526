import { allSettled } from '../esNext';

// eslint-disable-next-line max-params
export function AppStateService(
  $ionicHistory,
  $q,
  configService,
  intercomService,
  segmentService,
  sentryService,
  datadogService,
  profileService,
  notificationsService,
  keyboardService,
  lifecycleService,
  synchronizeService,
  firebaseService,
  apiUrlService,
  chatService,
  userExperienceService,
  localNotificationsService,
  organisationsService,
  themeService,
  dataStoreService,
  newsfeedService,
  accessRightsService,
  marketingService,
  externalDashboardService,
  satismeterService,
  localizationService
) {
  'ngInject';
  const methods = {
    initAppServices,
    initProfileServices,
    initOrganisationServices,
    resetProfileServices,
    resetViews,
  };

  function initAppServices() {
    return configService
      .getDatadogRUMConfiguration()
      .then((datadogRumConfig) => {
        datadogService.init(
          datadogRumConfig,
          configService.BaseConfig.ENV,
          configService.BaseConfig.APP_VERSION
        );
        sentryService.init();
        dataStoreService.init();
        intercomService.init();
        notificationsService.init();
        lifecycleService.init();
        keyboardService.init();
        localNotificationsService.init();
        marketingService.init();
        localizationService.displayAgreementIfNeeded();
      });
  }
  function initProfileServices(profile, organisation) {
    const loginToChat = () => {
      return chatService.canUseChat().then((canUse) => {
        return canUse ? chatService.login(profile) : $q.when();
      });
    };

    const settle = Promise.allSettled
      ? Promise.allSettled.bind(Promise)
      : allSettled;

    return $q
      .resolve(
        settle([
          firebaseService.registerUser(profile),
          synchronizeService.init(
            profile._id,
            profile.contents.organisation_id
          ),
          intercomService.registerUser(profile),
          segmentService.identify(profile, organisation),
          sentryService.setUser(profile),
          datadogService.setUser(profile),
          loginToChat(),
          userExperienceService.init(profile.contents.experience),
          accessRightsService.init(profile),
          satismeterService.trigger(profile, organisation),
        ])
      )
      .then(() => {
        notificationsService.registerMobileToken(profile);
        return profile;
      });
  }
  function initOrganisationServices(organisation) {
    organisationsService.setFeatureFlags(organisation);
    themeService.init(organisation);
    return organisation;
  }
  function resetProfileServices(apiUrlReset = true) {
    synchronizeService.reset();
    if (apiUrlReset) {
      apiUrlService.reset();
    }
    intercomService.reset();
    profileService.reset();
    chatService.reset();
    datadogService.reset();
    firebaseService.reset();
    notificationsService.reset();
    localNotificationsService.reset();
    userExperienceService.reset();
    accessRightsService.reset();
    newsfeedService.reset();
  }
  function resetViews() {
    // clear the whole tree of the routes
    return $ionicHistory.clearCache(['index']);
  }

  return methods;
}
