import { datadogRum } from '@datadog/browser-rum';
import { User } from '../../..';
import { Device } from '@capacitor/device';
import { DatadogRUMConfig } from '../Config/config.service';

export class DatadogService {
  Datadog = datadogRum;
  Device = Device;
  private enabled = false;
  started = false;

  constructor(private $log: ng.ILogService) {
    'ngInject';
  }

  init(config: DatadogRUMConfig, env, version) {
    this.enabled = config.DATADOG_ENABLED;
    if (this.enabled) {
      const allowedTracingOrigins = config.DATADOG_ALLOWED_TRACING_ORIGIN.map(
        (origin) => new RegExp(origin)
      );
      this.Datadog.init({
        applicationId: config.DATADOG_APPLICATION_ID,
        clientToken: config.DATADOG_CLIENT_TOKEN,
        site: config.DATADOG_SITE,
        service: config.DATADOG_SERVICE,
        env: env,
        version: version,
        sampleRate: config.DATADOG_SAMPLE_RATE,
        premiumSampleRate: config.DATADOG_PREMIUM_SAMPLE_RATE,
        trackInteractions: config.DATADOG_TRACK_INTERACTIONS,
        allowedTracingOrigins: allowedTracingOrigins,
      });
      this.started = true;
      this.$log.info('Datadog RUM SDK started');
      this.$log.debug(config);
    } else {
      this.$log.info('Datadog RUM SDK disabled');
      this.$log.debug(config);
    }
  }

  setUser(user: User): void {
    this.Datadog.setUser({
      id: user._id,
      org_id: user.contents.organisation_id,
      username: `${user.contents.firstName} ${user.contents.lastName}`,
      email: user.contents.email,
    });
    this.Device.getId().then((deviceInfo) => {
      this.Datadog.addRumGlobalContext('device', {
        UUID: deviceInfo.identifier,
      });
    });
  }

  reset(): void {
    this.Datadog.removeUser();
  }

  addError(error: unknown): void {
    if (this.enabled && this.started) {
      this.Datadog.addError(error);
    }
  }
}
