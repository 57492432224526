import type { AppSettingsService } from '../../../services/Utils/AppSettings/app-settings.service';

export const SettingsAlertComponent = {
  bindings: {
    onClose: '&',
  },
  templateUrl: 'components/Notifications/settings-alert/settings-alert.html',
  controller: class SettingsAlertController {
    constructor(private appSettingsService: AppSettingsService) {
      'ngInject';
    }

    open(): void {
      this.appSettingsService.open();
    }
  },
};
