import { CRUDFactory } from './crud-service.factory';
import { NB_CHUNK_ON_LIST, NB_PARAMS_SAVED_MAX } from './crud.config';

export default angular
  .module('sfMobile.services.crud', [
    'sfMobile.services.pov',
    'sfMobile.services.dates',
    'sfMobile.services.ping',
    'sfMobile.services.data-store',
  ])
  .factory('crudFactory', CRUDFactory)
  .constant('NB_CHUNK_ON_LIST', NB_CHUNK_ON_LIST)
  .constant('NB_PARAMS_SAVED_MAX', NB_PARAMS_SAVED_MAX).name;
