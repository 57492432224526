import {
  MediaCapture,
  MediaFile,
} from '@awesome-cordova-plugins/media-capture';
import { ForegroundNotificationService } from '../ForegroundNotification/foregroundNotification.service';
import { PlatformService } from '../Platform';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '@constants/feature-flags.constant';
import {
  Camera,
  CameraResultType,
  CameraSource,
  ImageOptions,
} from '@capacitor/camera';
import { FileSystemService } from '../FileSystem/file-system.service';

const PHOTO_LIMIT_SIZE = 2000;
const PHOTO_LIMIT_QUALITY = 90;
const PHOTO_CONFIG: ImageOptions = {
  resultType: CameraResultType.Uri,
  source: CameraSource.Camera,
  quality: PHOTO_LIMIT_QUALITY,
  width: PHOTO_LIMIT_SIZE,
  height: PHOTO_LIMIT_SIZE,
  allowEditing: false,
  saveToGallery: false,
  correctOrientation: true,
};

export class CameraService {
  MediaCapture = MediaCapture;
  Camera = Camera;

  constructor(
    private $q: ng.IQService,
    private filesSystemService: FileSystemService,
    private foregroundNotificationService: ForegroundNotificationService,
    private $window: ng.IWindowService,
  ) {
    'ngInject';
  }

  getPhotoAsFile(): ng.IPromise<File> {
    return this.takePhotoWithPlugin();
  }

  takePhotoWithPlugin(): ng.IPromise<File> {
    return this.Camera.getPhoto(PHOTO_CONFIG).then(
      (photo) =>
        this.filesSystemService.getBlobFromPath(
          photo.path as string
        ) as ng.IPromise<File>
    );
  }

  takeVideo(): ng.IPromise<MediaFile> {
    this.foregroundNotificationService.startForegroundService();
    return this.$q.resolve(
      this.MediaCapture.captureVideo()
        .then((videos) => videos[0])
        .finally(() => {
          this.foregroundNotificationService.stopForegroundService();
        })
    );
  }
}
