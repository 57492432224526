import { Device } from '@capacitor/device';

const V1_TOKEN_PREFIX = '$V1$';

export function UserDevicesService(
  profileService,
  usersService,
  objectIdService
) {
  'ngInject';

  this.Device = Device;
  /**
   * Create a user device entity object
   * @param {Object} device - Platform device
   * @param {String} mobileToken - Push notification token
   * @returns {Object} Mobile entity
   */
  const createUserDeviceEntity = (device, mobileToken, tokenVersion) => ({
    _id: objectIdService.create(),
    idfi: device.uuid,
    token: mobileToken,
    target: (device.platform || '').toLowerCase(),
    device: device.model,
    hasToSend: true,
    tokenVersion: tokenVersion ? tokenVersion : 'legacy',
  });

  /**
   * Delete the current device of the collection
   * @param {Array} mobileDevices - Mobile devices available
   * @returns {Array} Mobile devices clean
   */
  this.deleteDevice = (device, mobileDevices = []) =>
    mobileDevices.filter((userDevice) => {
      if (device.tokenVersion) {
        return (
          device.uuid !== userDevice.idfi ||
          !userDevice.tokenVersion ||
          device.tokenVersion !== userDevice.tokenVersion
        );
      } else {
        return device.uuid !== userDevice.idfi;
      }
    });
  /**
   * Add the current device to the collection
   * @param {String} token - Push notificaiton token
   * @param {Array} mobileDevices - Mobile devices available
   * @returns {Array} Mobile devices with the new device
   */
  this.addDevice = (device, token, mobileDevices = [], tokenVersion) => {
    return mobileDevices.concat(
      createUserDeviceEntity(device, token, tokenVersion)
    );
  };
  /**
   * Get the current devices from the user mobile devices
   * @returns {Array} Devices related to the current device
   */
  this.getMobileDevices = () =>
    profileService.getProfile().then((profile) => {
      const userDevices = profile.contents.mobileDevices || [];

      return this.Device.getInfo().then((devideInfo) =>
        userDevices.filter((userDevice) => devideInfo.uuid === userDevice.idfi)
      );
    });
  /**
   * Add the mobile devices to the current user
   * @param {String} mobileToken - Push notification token
   * @returns {Object} User entity
   */
  this.addMobileDevice = (mobileToken) => {
    return profileService.getProfile().then(
      (profile) => {
        let tokenVersion = 'legacy';
        if (mobileToken.startsWith(V1_TOKEN_PREFIX)) {
          tokenVersion = 'v1';
          mobileToken = mobileToken.substring(V1_TOKEN_PREFIX.length);
        }

        return this.Device.getInfo().then((deviceInfo) => {
          const newMobileDevice = createUserDeviceEntity(
            deviceInfo,
            mobileToken,
            tokenVersion
          );
          return usersService.addUserMobileDevices(profile, newMobileDevice);
        });
      },

      () => null // supress getProfile errors when user is not logged in
    );
  };
  /**
   * Delete the mobile devices to the current user
   * @returns {Object} User entity
   */
  this.deleteMobileDevice = () =>
    profileService.getProfile().then((profile) => {
      const mobileDevices = profile.contents.mobileDevices;

      return this.Device.getInfo().then((deviceInfo) => {
        const newMobileDevices = this.deleteDevice(deviceInfo, mobileDevices);

        return usersService.updateUserMobileDevices(profile, newMobileDevices);
      });
    });
}
