// eslint-disable-next-line max-params
export function LifecycleService(
  $timeout,
  $rootScope,
  $state,
  platformService,
  routerService,
  localStorageService,
  LIFECYCLE_STORAGE_KEYS,
  LIFECYCLE_EVENTS,
  pubSubService,
  debugService
) {
  'ngInject';
  const methods = {
    init,
    onPause,
    onResume,
  };

  function init() {
    platformService.on('pause', methods.onPause);
    platformService.on('resume', methods.onResume);
  }
  function onPause() {
    debugService.log('App Lifecycle: app have been paused');
    pubSubService.publish(LIFECYCLE_EVENTS.PAUSE);

    localStorageService.set(LIFECYCLE_STORAGE_KEYS.PAUSE, {
      state: {
        name: $state.current.name,
        params: $state.params,
      },
    });
  }
  function onResume(event) {
    pubSubService.publish(LIFECYCLE_EVENTS.RESUME);

    if (!event || !event.pendingResult) {
      return false;
    }

    const stateSaved = localStorageService.get(LIFECYCLE_STORAGE_KEYS.PAUSE);
    const doCustomBack = () => {
      routerService.disableNextBack();
      $state.go('index.menu-more.missions.lists.missions');
    };
    const oldSoftBack = $rootScope.$ionicGoBack;
    const deregisterSoftBack = () => {
      $rootScope.$ionicGoBack = oldSoftBack;
    };
    const deregisterHardBack = platformService.registerBackButtonAction(
      doCustomBack,
      101
    );
    let backStateChangeWatcher = null;
    const deregisterActions = () => {
      deregisterHardBack();
      deregisterSoftBack();

      backStateChangeWatcher();
    };
    const params = stateSaved.state.params || {};

    params.viewRestored = true;

    $rootScope.$ionicGoBack = () => doCustomBack();

    return $state.go(stateSaved.state.name, params).then(() => {
      backStateChangeWatcher = pubSubService.subscribe(
        pubSubService.GLOBAL_EVENTS.STATE_CHANGE_SUCCESS,
        deregisterActions
      );
      $timeout(() => {
        pubSubService.publish(
          LIFECYCLE_EVENTS.RESUME_RESULT,
          event.pendingResult
        );
      }, 0);
    });
  }

  return methods;
}
