import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { registerPlugin } from '@capacitor/core';
import {
  NOTIFICATION_METAEVENTS,
  NotificationMetaEvents,
} from '../../../notifications/services/notifications/notifications.constant';
import type { ModalService } from '../Modal';
import { PlatformService } from '../Platform';
import { PubSubService } from '../PubSub/pubsub.service';

const NOTIFICATION_PREFERENCE_KEY = 'mobile.notifications.enabled';

export type DeviceNotification = PushNotificationSchema & {
  data: { link: string };
  tap?: boolean;
};

export class PushNotificationsService {
  private initDefer: ng.IDeferred<string>;
  private isPushNotifAvailable: boolean;
  private hasSubscribed = false;
  private PushNotifications = PushNotifications; // original Capacitor PushNotification plugin
  private SelectedPushNotificationPlugin;

  // eslint-disable-next-line max-params
  constructor(
    private pubSubService: PubSubService<NotificationMetaEvents>,
    private $q: ng.IQService,
    private userDevicesService,
    private preferencesService,
    private modalService: ModalService,
    private platformService: PlatformService
  ) {
    'ngInject';
    this.initDefer = $q.defer();
    this.isPushNotifAvailable =
      this.platformService.isPluginAvailable('PushNotifications');
    const useCustomPushNotifPlugin =
      this.platformService.isAndroid() &&
      this.platformService.isPluginAvailable('SFPushNotifications');

    // CT-1603: we needed to implement an overriden version of Capacitor's PushNotification plugin for Android devices
    // because the solution implemented on iOS to handle two firebase apps simultaneously wasn't working
    // this mean that it should probably be removed when the legacy firebase API will be discontinued
    if (useCustomPushNotifPlugin) {
      this.SelectedPushNotificationPlugin = registerPlugin(
        'SFPushNotifications'
      );
    } else {
      this.SelectedPushNotificationPlugin = this.PushNotifications;
    }
  }

  /**
   * Init push notifications service
   * @return {Promise}         - Initialization is ready
   */
  init(): ng.IPromise<string> {
    if (!this.isPushNotifAvailable) {
      this.initDefer.resolve();

      return this.initDefer.promise;
    }

    this.register();

    if (!this.hasSubscribed) {
      this.SelectedPushNotificationPlugin.addListener(
        'registration',
        (token: Token): void => {
          this.initDefer.resolve(token.value);
          this.userDevicesService.addMobileDevice(token.value);
        }
      );

      this.SelectedPushNotificationPlugin.addListener(
        'registrationError',
        (): void => {
          this.initDefer.reject();
        }
      );

      this.SelectedPushNotificationPlugin.addListener(
        'pushNotificationReceived',
        (notif: PushNotificationSchema): void => {
          this.pubSubService.publish(
            NOTIFICATION_METAEVENTS.NOTIFICATION_RECEIVED,
            notif
          );
        }
      );
      // CT-1659 - 2nd solution to display foreground notifications on Android
      /* if(this.platformService.isAndroid()) {
        this.PushNotifications.addListener(
          'pushNotificationReceived',
          (notif: PushNotificationSchema): void => {
            console.log(`[CT-1603] ANDROID FOREGROUND NOTIF`);
            this.pubSubService.publish(
              NOTIFICATION_METAEVENTS.NOTIFICATION_RECEIVED,
              notif
            );
          }
        );
      } */

      this.SelectedPushNotificationPlugin.addListener(
        'pushNotificationActionPerformed',
        (notif: ActionPerformed): void => {
          this.pubSubService.publish(
            NOTIFICATION_METAEVENTS.NOTIFICATION_RECEIVED,
            {
              ...notif.notification,
              tap: true,
            }
          );
        }
      );

      this.hasSubscribed = true;
    }

    this.SelectedPushNotificationPlugin.removeAllDeliveredNotifications();

    return this.initDefer.promise;
  }

  register(): ng.IPromise<boolean> {
    if (!this.isPushNotifAvailable) {
      return this.$q.resolve(false);
    }

    return this.PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        this.SelectedPushNotificationPlugin.register();
      }

      return result.receive === 'granted';
    });
  }

  isEnabled(): ng.IPromise<boolean> {
    return this.preferencesService
      .getValue(NOTIFICATION_PREFERENCE_KEY)
      .then((isEnabled) => !!isEnabled);
  }

  enable(): ng.IPromise<void> {
    return this.register().then((isGranted) => {
      if (!isGranted) {
        this.openAuthorizeModal();
        return this.$q.reject('Push notifications are Unauthorizes');
      }

      return this.preferencesService.save(NOTIFICATION_PREFERENCE_KEY, true);
    });
  }

  disable(): ng.IPromise<void> {
    return this.preferencesService.save(NOTIFICATION_PREFERENCE_KEY, false);
  }

  openAuthorizeModal(): void {
    const template = `
      <sf-settings-alert
        on-close="$ctrl.onClose()">
      </sf-settings-alert>
    `;

    this.modalService.open(template);
  }
}
