/* Typedef */

import { APIPlacesList } from '@places/index';
import { ObjectId } from 'app';
import { IMAGE_SIZES } from '../../../constants/image-sizes.constant';
import {
  DISTANCE_UNITS,
  DISTANCE_UNITS_PREFERENCE_NAME,
} from '../../../constants/unit-system.constants';
import { AccessRightsService } from '../../Utils/AccessRights/access-rights.service';
import { ImageService } from '../../Utils/Image/image.service';
import { LocalizationService } from '../../Utils/Localization/localization.service';
import { TranslateNumbersService } from '../../Utils/TranslateNumbers/translateNumbers.service';
import { PovService } from '../POV/pov.service';

/**
 * Object that describe a localization on earth
 *
 * @typedef {Object} GeoCoordinates
 * @property {Integer} lat latitude
 * @property {Integer} lng longitude
 */

const USEFUL_FIELDS = [
  'contents.name',
  'contents.description',
  'contents.formatted_address',
  'contents.street',
  'contents.city',
  'contents.country',
  'contents.params',
  'contents.timezone',
  'contents.latLng',
  'contents.documents_ids',
  'contents.zipcode',
  'contents.administrative_area',
  'contents.picture_id',
];
const LOCAL_STORE_INCOMPLETE_SYNC_KEY = 'is_places_sync_incomplete';

const AVAILABLE_SEARCH_FILTER = {
  id: {
    remote: 'pos_id',
    local: 'id',
  },
  name: {
    remote: 'pos_name',
    local: 'name',
  },
  description: {
    remote: 'pos_description',
    local: 'description',
  },
  city: {
    remote: 'pos_city',
    local: 'contents.city',
  },
  zipcode: {
    remote: 'pos_zipcode',
    local: 'contents.zipcode',
  },
  country: {
    remote: 'pos_country',
    local: 'contents.country',
  },
  isAssigned: {
    // remote is not used because it already check only the right places
    remote: 'pos_is_assigned',
    local: 'isAssigned',
  },
};
const AVAILABLE_SORTING = {
  place_name: {
    remote: 'contents.name',
    local: 'name',
  },
  place_distance: {
    local: 'distance',
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PlacesService(
  translateNumbersService: TranslateNumbersService,
  localizationService: LocalizationService,
  $q: ng.IQService,
  $http: ng.IHttpService,
  DataSourceAdapterService,
  sfPOVService: PovService,
  imageService: ImageService,
  organisationsService,
  SF_DISTANCE_UNITS: typeof DISTANCE_UNITS,
  SF_DISTANCE_UNITS_PREFERENCE_NAME: typeof DISTANCE_UNITS_PREFERENCE_NAME,
  SF_IMAGE_SIZES: typeof IMAGE_SIZES,
  accessRightsService: AccessRightsService
) {
  'ngInject';
  const isRTLNeeded = localizationService.shouldActivateRTL();
  const adapter = new DataSourceAdapterService('places', {
    default_params: {
      mode: 'compact',
      fields: USEFUL_FIELDS,
    },
    backup: {
      indexed_fields: [
        'name',
        'distance',
        'isAssigned',
        'isTemporaryVisible',
        'isTerritoryScope',
      ],
    },
    take_care_of_user_profile: true,
    available_search_filter: AVAILABLE_SEARCH_FILTER,
    available_sorting: AVAILABLE_SORTING,
    customParamPrefix: 'pos_pos_customparam_',
    incomplete_sync_key: LOCAL_STORE_INCOMPLETE_SYNC_KEY,
  }) as Record<'saveLocal' | 'basePath' | 'listLocal' | 'updateLocal', any>;
  const methods = Object.assign(adapter, {
    save: savePlace,
    setPlacesDistanceFrom,
    formatDistance,
    calculateDistance,
    getPlacesStatistics,
    getReportsStatistics,
    getGoals,
    getGoalByForm,
    getAllPlacesLists,
    getStoreGroupById,
    getPlaces,
    getPlacePicture,
    isAllowedToSave,
    checkPlaceUsersRelations,
  });

  // API

  /**
   * Save the new place datas to the API
   *
   * @param {Object} place - the place to save
   * @param {Object} options - Request options
   * @return {Promise}         - Place statistics
   * @this placesService
   */
  function savePlace(place, options: Record<string, any> = {}) {
    const _this = methods;
    const saveUrl = _this.basePath + '/' + place.id;
    const requestConfig = {
      timeout: options.canceler ? options.canceler.promise : null,
    };
    const config = options.pov ? { pov: options.pov } : { pov: 'organisation' };

    return sfPOVService
      .pBuildURL(saveUrl, config)
      .then((url) => $http.patch(url, place, requestConfig))
      .then((response) => response.data)
      .then(transformPlace)
      .then((newPlace) =>
        _this.saveLocal(newPlace.id, newPlace).then(() => newPlace)
      );

    function transformPlace(newPlace) {
      newPlace.id = newPlace._id;
      delete newPlace.users;
      delete newPlace.placesLists;
      return newPlace;
    }
  }

  /**
   * Call the statistics of the place
   *
   * @param {String}   placeId - Id of the place
   * @return {Promise}         - Place statistics
   * @this placesService
   */
  function getPlacesStatistics(placeId) {
    const getStatsUrl = `${methods.basePath}/${placeId}/statistics`;

    return sfPOVService
      .pBuildURL(getStatsUrl, { pov: 'organisation' })
      .then($http.get)
      .then((response) => response.data);
  }

  /**
   * Call the reports statistics of the place
   *
   * @param {String}   placeId - Id of the place
   * @return {Promise}         - Reports statistics
   * @this placesService
   */
  function getReportsStatistics(placeId) {
    const getStatsUrl = `${methods.basePath}/${placeId}/reports/statistics`;

    return sfPOVService
      .pBuildURL(getStatsUrl)
      .then($http.get)
      .then((response) => response.data);
  }

  /**
   * Call the place goals
   *
   * @param {String}   placeId - Id of the place
   * @return {Promise}         - Reports statistics
   * @this placesService
   */
  function getGoals(placeId) {
    const getStatsUrl = `${methods.basePath}/${placeId}/goals`;

    return sfPOVService
      .pBuildURL(getStatsUrl)
      .then($http.get)
      .then((response) => response.data as Record<string, unknown>[]);
  }

  function getGoalByForm(placeId, formId) {
    return methods
      .getGoals(placeId)
      .then(
        (formGoals) => formGoals.filter((goal) => formId === goal.form_id)[0]
      );
  }

  /**
   * Call all places lists
   *
   * @param {Object}   params - Request params
   * @return {Promise}         - Places lists
   * @this PlacesService
   */
  function getAllPlacesLists(
    params: Record<string, unknown> = {}
  ): ng.IPromise<APIPlacesList> {
    params = {
      mode: 'compact',
      ...params,
    };

    return sfPOVService
      .pBuildURL('/placesLists', { pov: 'organisation' })
      .then((url) => $http.get<APIPlacesList>(url, { params }))
      .then((response) => response.data);
  }

  function getStoreGroupById(storeGroupId: ObjectId) {
    const params = {
      mode: 'compact',
    };

    return sfPOVService
      .pBuildURL(`/placesLists/${storeGroupId}`, { pov: 'organisation' })
      .then((url) => $http.get(url, { params }))
      .then((response) => response.data);
  }

  function getPlaces(params: Record<string, unknown> = {}) {
    params = {
      mode: 'compact',
      ...params,
    };

    return sfPOVService
      .pBuildURL('/places', { pov: 'user' })
      .then((url) => $http.get(url, { params }))
      .then((response) => response.data);
  }

  function getPlacePicture(place, size) {
    const promise = place.contents.picture_id
      ? $q.when(place.contents.picture_id)
      : organisationsService
          .getPreference('default_pos_picture_id') // not every org has this setting
          .catch(() => null);

    return promise.then(
      (pictureId) =>
        pictureId && // prevents unhandled rejection errors
        imageService.getSizedUrlFromId(
          pictureId,
          size || SF_IMAGE_SIZES.RECTANGLE_MEDIUM
        )
    );
  }

  /**
   * Add a 'distance' column in local database to be
   * able to sort by this field
   *
   * @param {GeoCoordinates} from - Distance is calculated between from and places
   * @return {Promise}            - Places lists
   * @this placesService
   */
  function setPlacesDistanceFrom(from) {
    const _this = methods;

    return _this.listLocal().then((places) => {
      const placesWithDistance = places.map((place) => {
        if (
          from &&
          place.contents &&
          place.contents.latLng &&
          place.contents.latLng.length
        ) {
          const universalDistance = calculateDistance(from, {
            lat: place.contents.latLng[0],
            lng: place.contents.latLng[1],
          });

          place.distance = universalDistance;
        }
        return place;
      });

      return $q.all(placesWithDistance.map(_this.updateLocal.bind(_this)));
    });
  }

  function formatDistance(distance) {
    return getDistanceUnitFromPreferences().then((unit) => {
      const PRECISION = 2;
      const roundedDistance = isRTLNeeded
        ? translateNumbersService.changeNumberEnglishToArabic(
            unit.fromKm(distance).toFixed(PRECISION)
          )
        : unit.fromKm(distance).toFixed(PRECISION);

      return `${roundedDistance}${unit.abbreviation}`;
    });
  }

  /**
   * Calculate the distance in km between 2 points on a earth given their coordinates
   *
   * @param {GeoCoordinates} from first position
   * @param {GeoCoordinates} to second position
   * @return {Number} Distance in kilometers
   */

  function calculateDistance(
    { lat: lat_a, lng: lng_a },
    { lat: lat_b, lng: lng_b }
  ) {
    const { sin, cos, sqrt, atan2, pow } = Math;
    const square = (x) => pow(x, 2);
    const EARTH_RADIUS = 6371;
    // in rad
    const deltaLat = deg2rad(lat_b - lat_a);
    const deltaLng = deg2rad(lng_b - lng_a);

    // [Haversine formula](https://en.wikipedia.org/wiki/Haversine_formula)
    const a =
      square(sin(deltaLat / 2)) +
      cos(deg2rad(lat_a)) * cos(deg2rad(lat_b)) * square(sin(deltaLng / 2));
    // angular distance (as in math, not the js framework)
    const angularDistance = 2 * atan2(sqrt(a), sqrt(1 - a));

    return EARTH_RADIUS * angularDistance;
  }

  /**
   * Convert degrees into radians (most useful in trigonometry formulas)
   *
   * @param {Integer} angleInDegrees angle in degree unit
   * @returns {Integer} same angle in radians
   */
  function deg2rad(angleInDegrees) {
    return (angleInDegrees * Math.PI) / 180;
  }

  /**
   * @returns {Object} - The current organisation distance unit definition.
   */
  function getDistanceUnitFromPreferences() {
    return organisationsService
      .getPreference(SF_DISTANCE_UNITS_PREFERENCE_NAME)
      .catch(() => 'metric')
      .then(
        (orgUnitSystem) =>
          SF_DISTANCE_UNITS.filter(
            (unit) =>
              unit.system ===
              (isRTLNeeded && orgUnitSystem == 'metric'
                ? 'metric_ar'
                : orgUnitSystem)
          )[0]
      );
  }

  function isAllowedToSave() {
    return $q
      .all([
        accessRightsService.isAtLeastContentManager(),
        organisationsService
          .getPreference('settings.user_and_manager_can_edit_place')
          .catch(() => null),
      ])
      .then(([isAtLeastContentManager, preference]) =>
        Boolean(isAtLeastContentManager || preference)
      );
  }

  function checkPlaceUsersRelations(place_id, usersIds, params = {}) {
    const url = `${methods.basePath}/${place_id}/checkrelations`;

    return sfPOVService
      .pBuildURL(url, { pov: 'organisation' })
      .then((url) =>
        $http.post<Record<string, boolean>[]>(url, { usersIds }, { params })
      )
      .then((response) => response.data)
      .then((usersRelationsList) =>
        usersRelationsList.reduce((acc, rel) => ({ ...acc, ...rel }), {})
      );
  }

  return methods;
}
