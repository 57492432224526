import type { CrudFactory } from './../../Utils/CRUD/crud-service.factory';
import { IAPIResource } from '../../..';
import { DB_SCHEMA } from '../../../core/database.config';
import type {
  DataAccessLayerService,
  LimitParams,
  QueryParams,
  SortParams,
} from '../../Utils/CRUD/crud-service';
import { PovService } from '../POV/pov.service';
import { isNil } from 'ramda';
import { IPromise } from 'angular';
import { ImageService } from '@services/Utils/Image/image.service';
import { IMAGE_SIZES } from '@constants/image-sizes.constant';
import { PubSubService } from '@services/Utils/PubSub/pubsub.service';

type ProductsDefaultParams = {
  filters?: Record<string, any>;
  limit?: number;
  offset?: number;
  sorts?: string[];
  search?: string;
};

export const PUB_SUB_IS_HUGE_AND_OFFLINE = 'IS_HUGE_AND_OFFLINE';
export const MAX_POSSIBLE_PRODUCTS_IN_DB = 20_000;
const ORGANISATION_PRODUCTS_COUNT = 'organisation_product_count';
const PRODUCTS_DEFAULT_PARAMS = {
  filters: {},
  limit: 20,
  offset: 0,
  sorts: ['contents.name', '_id'],
} as ProductsDefaultParams;

const callApiIfNeeded = (data, prommiseFn) => {
  if (data.length) {
    return data;
  }

  return prommiseFn();
};

const filterByNameAndEan = (contents, querySearch) => {
  const name = contents.name.toLowerCase();
  const ean = contents.ean.toLowerCase();
  const search = querySearch.toLowerCase() || '';

  return name.includes(search) || ean.includes(search);
};

export class ProductsService {
  crud: DataAccessLayerService<Product>;
  interval: number;

  private getSearchParams = (querySearch) => {
    return {
      ...(querySearch
        ? {
            contents: (contents) => filterByNameAndEan(contents, querySearch),
          }
        : {}),
    };
  };

  constructor(
    private crudFactory: CrudFactory<Product>,
    private databaseSchema: DB_SCHEMA,
    private $http: ng.IHttpService,
    private $q: ng.IQService,
    private sfPOVService: PovService,
    private localStorageService: ng.local.storage.ILocalStorageService,
    private readonly imageService: ImageService,
    private readonly SF_IMAGE_SIZES: typeof IMAGE_SIZES,
    private pingService,
    private SF_ERROR_CODES,
    private pubSubService: PubSubService
  ) {
    'ngInject';
    this.crud = this.crudFactory('products', {
      take_care_of_user_profile: true,
      backup: {
        indexed_fields: databaseSchema.tables.products.indexed_fields.map(
          (field) => field.name
        ),
      },
    }) as DataAccessLayerService<Product>;
  }

  getProductsByApi({
    search,
    offset: skip,
    filters,
    ...queryParams
  } = PRODUCTS_DEFAULT_PARAMS): ng.IPromise<Product[]> {
    const searchFilters = search
      ? {
          $or: [{ ean: { $regex: search } }, { name: { $regex: search } }],
        }
      : null;

    const params = {
      filters: {
        $and: [
          ...(searchFilters ? [searchFilters] : []),
          ...(filters ? [filters] : []),
        ],
      },
      skip,
      ...queryParams,
    };

    return this.pingService
      .ping()
      .then(() => this.sfPOVService.pBuildURL('/products'))
      .then((url) =>
        this.$http
          .get<Product[]>(url, { params })
          .then(({ data }) => data?.entries as unknown as Product[])
      );
  }

  getProductsLocally(
    params: QueryParams,
    limit: LimitParams,
    sorts?: SortParams
  ) {
    return this.crud.queryLocal(params, limit, sorts);
  }

  getProducts(
    queryParams: { search?: string } = {},
    limit = PRODUCTS_DEFAULT_PARAMS
  ): ng.IPromise<Product[]> {
    return this.getProductsCountPerOrganisation().then((productsCount) => {
      const params = this.getSearchParams(queryParams.search);

      return productsCount < MAX_POSSIBLE_PRODUCTS_IN_DB
        ? this.getProductsLocally(params, limit)
        : this.getProductsByApi({ ...queryParams, ...limit });
    });
  }

  getByEans(eans: string[]): ng.IPromise<Product[]> {
    const getPrommiseFn = () =>
      this.getProductsByApi({
        filters: { ean: { $in: eans } },
        sorts: ['contents.name'],
      });

    return this.crud
      .queryLocal({ ean: eans })
      .catch(() => {
        console.error(`can't get products by eans`);
        return [];
      })
      .then((products) => callApiIfNeeded(products, getPrommiseFn))
      .catch(() => []);
  }

  getByIds(ids: string[]): ng.IPromise<Product[]> {
    const getPromiseFn = () =>
      this.getProductsByApi({
        filters: { id: { $in: ids } },
        sorts: ['contents.name'],
      });

    return this.crud
      .queryLocal({ id: ids })
      .catch(() => {
        console.error(`can't get products by ids`);
        return [];
      })
      .then((products) => callApiIfNeeded(products, getPromiseFn))
      .catch(() => []);
  }

  getProductsCountPerOrganisation(skipLocalStorage = false): IPromise<number> {
    const count = this.localStorageService.get(ORGANISATION_PRODUCTS_COUNT);

    if (!skipLocalStorage && !isNil(count)) {
      return this.$q.resolve(count);
    }

    return this.sfPOVService
      .pBuildURL('/products/count')
      .then((url) =>
        this.$http
          .get<{ productsCount: number }>(url)
          .then(({ data }) => data?.productsCount)
      );
  }

  pingHugeAndOffline(): IPromise<{
    isHugeCounts: boolean;
    isOffline: boolean;
  }> {
    let isHugeCounts = false;
    let isOffline = false;

    return this.getProductsCountPerOrganisation()
      .then((count) => count > MAX_POSSIBLE_PRODUCTS_IN_DB)
      .then((isHuge) => {
        isHugeCounts = isHuge;

        return this.pingService.ping(false);
      })
      .then(() => {
        return { isHugeCounts, isOffline };
      })
      .catch((e) => {
        isOffline = this.SF_ERROR_CODES.CODES_SERVER_NO_NETWORK.includes(
          e.status
        );

        return { isHugeCounts, isOffline };
      })
      .then((res) => {
        this.killPingInterval();

        this.interval = window.setInterval(() => {
          this.pingHugeAndOffline();
        }, 1000);

        this.pubSubService.publish(PUB_SUB_IS_HUGE_AND_OFFLINE, res);

        return res;
      });
  }

  killPingInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  synchronizeProducts(): IPromise<Product[]> {
    return this.getProductsCountPerOrganisation(true).then((productsCount) => {
      this.localStorageService.set(ORGANISATION_PRODUCTS_COUNT, productsCount);

      if (productsCount > MAX_POSSIBLE_PRODUCTS_IN_DB) {
        return [];
      }

      return this.crud.apiList({});
    });
  }

  getByCatalogs(catalogsIds: string[], params): ng.IPromise<Product[]> {
    const getPrommiseFn = () =>
      this.getProductsByApi({
        ...params,
        filters: { catalog: { $in: catalogsIds } },
        sorts: ['contents.name', '_id'],
      });

    return this.getProductsLocally({ catalog_id: catalogsIds }, {}, [
      { key: 'name', desc: true },
    ])
      .then((products) => {
        // filters
        return products.filter(({ contents }) =>
          filterByNameAndEan(contents, params?.search)
        );
      })
      .then((products) => {
        // limit
        if (!params?.limit) {
          return products;
        }

        const offset = params?.offset || params?.skip || 0;
        const limit = params?.limit + offset;

        return products.slice(offset, limit);
      })
      .then((products) => callApiIfNeeded(products, getPrommiseFn))
      .catch(() => []);
  }

  getProductImageUrls(product: Product): IPromise<ProductPictureUrls> {
    if (!product.picture || !product.picture._id) {
      return this.$q.when({});
    }

    return this.$q
      .all([
        this.imageService.getSizedUrlFromId(
          product.picture._id,
          this.SF_IMAGE_SIZES.SQUARE_BIG
        ),
        this.imageService.getSizedUrlFromId(
          product.picture._id,
          this.SF_IMAGE_SIZES.RECTANGLE_MEDIUM
        ),
        this.imageService.getSizedUrlFromId(
          product.picture._id,
          this.SF_IMAGE_SIZES.SQUARE_SMALL
        ),
      ])
      .then(([full, medium, thumb]) => {
        return { full, medium, thumb };
      });
  }
}

type ProductContents = {
  ean: string;
  name: string;
  price: {
    currency: string;
    value: number;
  };
  brand: string;
  catalog_name: string;
  catalog_id: string;
  category: string;
  internalId: string;
  version: string;
  size: string;
};

export type ProductPictureUrls = {
  full?: string;
  medium?: string;
  thumb?: string;
};
export type Product = IAPIResource<ProductContents> & {
  picture?: { _id?: string };
  imageUrls?: ProductPictureUrls;
};
