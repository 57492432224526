import { ObjectId, Organisation, Subscription } from '..';
import { AuthService } from '../services/API/auth/auth.service';
import { ImageService } from '../services/Utils/Image/image.service';
import { PopupService } from '../services/Utils/Popup/popup.service';
import {
  PubSubService,
  UnregisterFn,
} from '../services/Utils/PubSub/pubsub.service';
import {
  Company,
  MultiOrganisationService,
} from './services/multi-organisation.service';
import { StateService } from '@uirouter/angularjs';

const POPUP_SUCCESS_TIMEOUT_MS = 600;

export class MultiOrganisationController implements ng.IComponentController {
  // Bindings
  organisation: Organisation;

  // State
  isEmpty: boolean;
  isLoading: boolean;
  networkError: boolean;

  // Property
  allOrganisations: Company[];
  logoUrls: Record<ObjectId, string>;
  organisations: Company[];
  organisationSearch: string;
  organisationSwitchListener: UnregisterFn;

  constructor(
    private $q: ng.IQService,
    private $timeout: ng.ITimeoutService,
    private $translate: ng.translate.ITranslateService,
    private authService: AuthService,
    private imageService: ImageService,
    private multiOrganisationService: MultiOrganisationService,
    private organisationsService,
    private popupService: PopupService,
    private profileService,
    private pubSubService: PubSubService,
    private synchronizeService,
    private SF_IMAGE_SIZES,
    private $log: ng.ILogService,
    private $state: StateService
  ) {
    'ngInject';
  }

  $onInit(): ng.IPromise<void> {
    this.organisationSwitchListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.ORGANISATION_SWITCH,
      (data: { profile }) => {
        return this.organisationsService
          .getProfileOrganisation(data.profile)
          .then((organisation) => {
            this.organisation = organisation;
            this.reload();
          });
      }
    );
    return this.reload();
  }

  $onDestroy(): void {
    if (this.organisationSwitchListener) {
      this.organisationSwitchListener();
    }
  }

  reload(): ng.IPromise<void> {
    this.isLoading = true;
    this.networkError = false;

    return this.fetchOrganisations()
      .catch(() => {
        this.networkError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  fetchOrganisations(): ng.IPromise<void> {
    return this.multiOrganisationService
      .getOrganisations({})
      .then((organisations) => {
        this.organisations = organisations.entries.filter(
          (org) => org.id !== this.organisation._id
        );
        this.allOrganisations = organisations.entries;
        this.isEmpty = this.organisations.length === 0;

        return this.getLogoUrls(organisations.entries);
      })
      .then((urls) => {
        this.logoUrls = urls.reduce<Record<ObjectId, string>>(
          (acc, url, index) => {
            Object.assign(acc, { [this.allOrganisations[index].id]: url });
            return acc;
          },
          {}
        );
      });
  }

  onOrganisationSearchChange(search: string): void {
    this.organisationSearch = search;
  }

  switchToOrganisation(organisation: Company): ng.IPromise<void> {
    const state = { nb: 2, current: 0 };
    const loadingPopup = this.popupService.showProgress(state, {
      title: this.$translate.instant('MULTI_ORGANISATION_SWITCHING_TITLE', {
        company: organisation.name,
      }),
      desc: this.$translate.instant('MULTI_ORGANISATION_SWITCHING_DESC'),
    });

    return this.authService
      .switchToOrganisation(organisation.id)
      .then((profile) => {
        state.current = 1;

        this.synchronizeService.init(
          profile._id,
          profile.contents.organisation_id
        );

        return this.synchronizeService.synchronize().then(() => {
          state.current = 2;
          this.pubSubService.publish(
            this.pubSubService.GLOBAL_EVENTS.ORGANISATION_SWITCH,
            { profile }
          );
          loadingPopup.close();
          const successPopup = this.popupService.showSuccess(
            this.$translate.instant(
              'MULTI_ORGANISATION_SWITCHING_SUCCESS_TITLE',
              {
                company: organisation.name,
              }
            )
          );
          this.$timeout(() => {
            successPopup.close();
            this.$state.reload();
          }, POPUP_SUCCESS_TIMEOUT_MS);
        });
      })
      .catch((err) => {
        this.$log.error(err);
        this.popupService.showSaveError(
          {
            title: this.$translate.instant(
              'MULTI_ORGANISATION_SWITCHING_ERROR_TITLE'
            ),
          },
          () => {
            loadingPopup.close();
            return this.switchToOrganisation(organisation);
          },
          () => {
            loadingPopup.close();
            return this.profileService.getLocalProfile().then((profile) => {
              return this.organisationsService
                .getProfileOrganisation(profile)
                .then((organisation) => {
                  this.organisation = organisation;
                  this.reload();
                });
            });
          }
        );
      });
  }

  getLogoUrls(organisations: Company[]): ng.IPromise<string[]> {
    return this.$q.all(
      organisations.map((org) =>
        org.logo_id
          ? this.imageService.getSizedUrlFromId(
              org.logo_id,
              this.SF_IMAGE_SIZES.SQUARE_SMALL,
              org.id
            )
          : this.$q.when('')
      )
    );
  }

  getSubscriptionString(subscription: Subscription): string {
    if (subscription.status === 'in_trial') {
      return `(${this.$translate.instant(
        'MULTI_ORGANISATION_SUBSCRIPTION_TRIAL'
      )})`;
    }
    if (subscription.status === 'demo') {
      return `(${this.$translate.instant(
        'MULTI_ORGANISATION_SUBSCRIPTION_DEMO'
      )})`;
    }
    return '';
  }
}
