import { FEATURE_FLAGS } from '@constants/feature-flags.constant';
import { DraftReport } from '@services/API/draft-reports/draft-reports';
import { TranslationDictionnary } from '@services/Utils/sfTranslation/sf-translation.filters';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { Tab } from '@simplifield/webcomponents/dist/types/services/tabs';
import { StateParams } from '@uirouter/angularjs';
import { StateService, TransitionPromise } from '@uirouter/core';
import { CampaignsUtilsService } from 'app/reactive-campaigns/services/campaigns-utils.service';
import { indexBy, prop } from 'ramda';
import type { ObjectId, TSFixMe, UserContents } from '../../..';
import { ContentService } from '../../../components/Layout/content/content.service';
import type { APIStoreContents } from '../../../places';
import { Campaign, Tag } from '../../../services/API/campaigns/campaigns';
import { CampaignsService } from '../../../services/API/campaigns/campaigns.service';
import { DraftReportsService } from '../../../services/API/draft-reports/draft-reports.service';
import { PlacesService } from '../../../services/API/places/places.service';
import {
  Report,
  ReportCampaignContents,
} from '../../../services/API/reports/reports';
import { ReportsService } from '../../../services/API/reports/reports.service';
import { ActionSheetService } from '../../../services/Utils/ActionSheet/action-sheet.service';
import {
  Identified,
  QueryParams,
} from '../../../services/Utils/CRUD/crud-service';
import { DateService } from '../../../services/Utils/Dates/date.service';

type ReportFilter = {
  name: string;
  value: string | boolean;
  operator?: string;
};

const NB_REPORT_TO_GET = 20;
const REPORTS_LIST_SEARCH_CRITERIAS = [
  'campaign_name',
  'user_firstName',
  'user_lastName',
  'pos_name',
  'pos_street',
  'pos_city',
];

const uniq = <T>(xs: T[]) => [...new Set(xs)];

export const ReactiveCampaignReportsComponent: ng.IComponentOptions = {
  bindings: {
    campaignId: '<?',
    contentKey: '@',
    key: '@',
    onHideLoading: '&',
  },
  templateUrl:
    'reactive-campaigns/components/reactive-campaign-reports/reactive-campaign-reports.html',
  controller: class ReactiveCampaignReportsController implements ng.IController {
    /** Bindings */
    campaignId?: ObjectId;
    contentKey: string;
    key: string;
    onHideLoading: () => void;

    /** Fields */
    campaigns: Record<string, Identified<Campaign>> = {};
    allCampaigns: Record<string, Identified<Campaign>> = {};
    canCallMore = false;
    drafts: Report[] = [];
    toBeSentReports: Report[] = [];
    hasReports: boolean;
    infiniteLoadError: boolean;
    isEmpty: boolean;
    isLoading: boolean;
    isSearchLoading: boolean;
    networkError: boolean;
    onScrollComplete: () => void;
    onTabPovClick: (index: number) => ng.IPromise<Report[]>;
    placeId?: ObjectId;
    places: Record<ObjectId, Partial<APIStoreContents>> = {};
    reports: Report[];
    requestPaginate;
    reportsParams = {
      'sorts[]': '-saved.seal_date',
      fromCampaign: true,
    };
    searchError: boolean;
    searchField: string;
    userId: ObjectId;
    users: Record<ObjectId, Partial<UserContents>>;
    values: Tab[];
    emptyTagsMap = [];

    campaignsNamesIndex = {};

    isDraftReportsFlagEnabled = false;

    constructor(
      private $q: ng.IQService,
      private $state: StateService,
      private $stateParams: StateParams,
      private $translate: ng.translate.ITranslateService,
      private apiUtilsService,
      private campaignsService: CampaignsService,
      private contentService: ContentService,
      private placesService: ReturnType<typeof PlacesService>,
      private profileService,
      private reportsService: ReportsService,
      private RequestsPaginate,
      private placesSourceAdapterService,
      private campaignsUtilsService: CampaignsUtilsService,
      private sfFeatureFlagsService: SfFeatureFlags,
      private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
      private draftReportsService: DraftReportsService,
      private actionSheetService: ActionSheetService,
      private dateService: DateService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<Report[] | void> {
      this.placeId = this.$stateParams.placeId;
      this.onTabPovClick = this.changeReportsPov.bind(this);
      const myResponseTabIsActive = this.contentKey === 'reportsContent';

      this.values = [
        {
          label: this.$translate.instant('REPORTS_LIST_OTHER_TAB'),
          active: !myResponseTabIsActive,
        },
        {
          label: this.$translate.instant('REPORTS_LIST_MINE_TAB'),
          active: myResponseTabIsActive,
        },
      ];

      this.isDraftReportsFlagEnabled = this.sfFeatureFlagsService.hasFeature(
        this.SF_FEATURE_FLAGS.DRAFT_REPORTS
      );

      return this.reload();
    }

    reload(): ng.IPromise<Report[] | void> {
      this.networkError = false;
      this.searchError = false;
      const url = this.campaignId
        ? `/campaigns/${this.campaignId}/reports`
        : `/reports`;

      this.requestPaginate = new this.RequestsPaginate(
        this.reportsService.crud.simpleApiList.bind(
          this.reportsService.crud,
          url
        ),
        {
          limit: NB_REPORT_TO_GET,
          relatedEntitiesKeys: ['users', 'places', 'campaigns'],
        }
      );
      this.initReports();

      return this.profileService
        .getLocalProfile()
        .then((profile) => {
          this.userId = profile._id;
          this.users = { [this.userId]: profile.contents };
        })
        .then(() => this.callData())
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.onHideLoading();
        });
    }

    onSearchChange(search: string): ng.IPromise<Report[] | void> {
      this.isSearchLoading = true;
      this.searchError = false;
      this.searchField = search;

      this.initReports();

      return this.getReports()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    }

    /* Drafts fetch function */

    getDraftParams(
      campaignId: ObjectId | undefined,
      placeId: ObjectId | undefined
    ): ng.IPromise<{ campaign_id?: string } | { campaign_id: string[] }> {
      if (campaignId) {
        return this.$q.when(
          this.draftReportsService.buildLocalParams(campaignId, placeId)
        );
      }

      return this.campaignsService.crud.listLocal().then((campaigns) => ({
        campaign_id: campaigns.map((campaign) => campaign._id),
        ...(placeId ? { place_id: placeId } : {}),
      }));
    }

    fetchToBeSent(): ng.IPromise<void> {
      return this.reportsService.crud
        .queryLocal({
          ...(this.campaignId ? { campaign_id: this.campaignId } : {}),
          ...(this.placeId ? { place_id: this.placeId } : {}),
          localStatus: ['toBeSent'],
        })
        .then((reports) => {
          return this.fetchSendLaterReportsData(reports);
        });
    }

    fetchDrafts(): ng.IPromise<void> {
      const localDraftsPromise = this.getDraftParams(
        this.campaignId,
        this.placeId
      ).then((params) =>
        this.reportsService.crud.queryLocal(
          {
            ...params,
            localStatus: ['ready', 'draft'],
          },
          undefined,
          [
            {
              key: 'saved.seal_date',
            },
          ]
        )
      );

      const remoteDraftsPromise = this.isDraftReportsFlagEnabled
        ? this.draftReportsService.getAll(
            {
              campaignId: this.campaignId,
              ...(this.placeId ? { placeId: this.placeId } : {}),
            },
            false
          )
        : this.$q.resolve([]);

      return this.$q
        .all([localDraftsPromise, remoteDraftsPromise])
        .then(([localDrafts, remoteDrafts]) => {
          const remoteDraftsIds = new Set(remoteDrafts.map(({ _id }) => _id));
          // We prioritise remote reports when there is an id conflict
          const onlyLocalDrafts = localDrafts.filter(
            ({ _id }) => !remoteDraftsIds.has(_id)
          );

          return [...remoteDrafts, ...onlyLocalDrafts].sort((b, a) => {
            return this.dateService.compareDates(
              a.modified_date,
              b.modified_date
            );
          });
        })
        .then((drafts) => {
          return this.fetchReportsLocalData(drafts);
        });
    }

    deleteDraftReport(report: Report | DraftReport): ng.IPromise<void> {
      return this.openDeleteConfirm('draft').then((action) => {
        if (action === 'confirm_delete') {
          const isReportLocalOnly = ['draft', 'toBeSent'].includes(
            report.localStatus
          );

          const promises = [
            this.reportsService.deleteLocally(report._id ?? report.id),
          ];

          if (isReportLocalOnly) {
            promises.push(
              this.$q.when(
                this.draftReportsService.deleteLocally(report._id ?? report.id)
              )
            );
          } else {
            promises.push(
              this.draftReportsService.deleteOne(report._id ?? report.id)
            );
          }

          this.$q.all(promises).finally(() => {
            this.reload();
          });
        }
      });
    }

    deleteToBeSentReport(report: Report): ng.IPromise<void> {
      return this.openDeleteConfirm('toBeSent').then((action) => {
        if (action === 'confirm_delete') {
          return this.reportsService
            .deleteLocally(report._id ?? report.id)
            .then(() => {
              this.reload();
            });
        }
      });
    }

    openDeleteConfirm(
      reportStatus: 'draft' | 'toBeSent' = 'draft'
    ): ng.IPromise<string> {
      const draftTranslationKeys = {
        title: 'DRAFT_REPORTS_DELETE_MODAL_TITLE',
        cancelText: 'DRAFT_REPORTS_DELETE_MODAL_CANCEL',
        destructiveText: 'DRAFT_REPORTS_DELETE_MODAL_CONFIRM',
      };
      const toBeSentTranslationKeys = {
        title: 'TO_BE_SENT_REPORTS_DELETE_MODAL_TITLE',
        cancelText: 'TO_BE_SENT_REPORTS_DELETE_MODAL_CANCEL',
        destructiveText: 'TO_BE_SENT_REPORTS_DELETE_MODAL_CONFIRM',
      };

      const config =
        reportStatus === 'draft'
          ? draftTranslationKeys
          : toBeSentTranslationKeys;

      const confirmPromise: ng.IDeferred<string> = this.$q.defer();
      const actionSheetConfig = {
        title: this.$translate.instant(config.title),
        cancelText: this.$translate.instant(config.cancelText),
        destructiveText: this.$translate.instant(config.destructiveText),
        isValidation: true,
      };

      const onCancelClick = () => confirmPromise.reject('cancel_delete');
      const onDestructiveClick = () => confirmPromise.resolve('confirm_delete');

      this.actionSheetService.open(
        [],
        actionSheetConfig,
        onCancelClick,
        onDestructiveClick
      );

      return confirmPromise.promise;
    }

    deleteDraftedReports(campaigns: Campaign[], reports: Report[]): string[] {
      const draftedCampaignIds = campaigns
        .filter((campaign) => this.campaignsService.isDraft(campaign))
        .map(({ _id }) => _id);

      const reportsIdsForRemove = reports
        .filter(({ contents }) => {
          const campaignId = (contents as ReportCampaignContents).campaign_id;
          const campaign = campaigns.find(({ _id }) => _id === campaignId);

          const isDraft = draftedCampaignIds.includes(campaignId);
          const hasSameVersion =
            contents.campaign_version === campaign?.contents.version;

          return isDraft || !hasSameVersion;
        })
        .map(({ _id }) => _id);

      reportsIdsForRemove.forEach((id) =>
        this.reportsService.deleteLocally(id)
      );

      return reportsIdsForRemove;
    }

    fetchSendLaterReportsData(reports: Report[]): ng.IPromise<void> {
      const campaignsIds = uniq<ObjectId>(
        reports.map(
          ({ contents }) => (contents as ReportCampaignContents).campaign_id
        )
      );

      return this.$q
        .resolve(this.campaignsService.getCampaignsListByIds(campaignsIds))
        .catch(() =>
          this.campaignsService.crud.queryLocal({ _id: campaignsIds })
        )
        .then((campaigns) => {
          const removedReportIds = this.deleteDraftedReports(
            campaigns,
            reports
          );

          const shapedCampaigns = campaigns.map((campaign) => ({
            id: campaign._id,
            ...campaign,
          }));

          this.toBeSentReports = reports.filter(
            ({ _id: reportId, contents: reportContents }) => {
              const reportsCampaignId = (
                reportContents as ReportCampaignContents
              ).campaign_id;
              const notArhived = campaigns.some(
                ({ _id, archived }) => _id === reportsCampaignId && !archived
              );
              const notDraft = !removedReportIds.includes(reportId);

              return notArhived && notDraft;
            }
          );
          this.campaigns = indexBy(prop('_id'), shapedCampaigns);

          reports.forEach((report) => {
            this.campaignsNamesIndex[report._id] =
              this.campaigns[
                (report.contents as ReportCampaignContents).campaign_id
              ].contents.name;
          });

          return this.fetchPlaces(reports, campaigns);
        });
    }

    fetchReportsLocalData(reports: Report[]): ng.IPromise<void> {
      const campaignsIds = uniq<ObjectId>(
        reports.map(
          ({ contents }) => (contents as ReportCampaignContents).campaign_id
        )
      );

      return this.$q
        .resolve(this.campaignsService.getCampaignsListByIds(campaignsIds))
        .catch(() =>
          this.campaignsService.crud.queryLocal({ _id: campaignsIds })
        )
        .then((campaigns) => {
          const removedReportIds = this.deleteDraftedReports(
            campaigns,
            reports
          );

          const shapedCampaigns = campaigns.map((campaign) => ({
            id: campaign._id,
            ...campaign,
          }));

          this.drafts = reports.filter(
            ({ _id: reportId, contents: reportContents }) => {
              const reportsCampaignId = (
                reportContents as ReportCampaignContents
              ).campaign_id;
              const notArhivedAndInActivePeriod = campaigns.some((campaign) => {
                return (
                  campaign._id === reportsCampaignId &&
                  !campaign.archived &&
                  this.campaignsUtilsService.isFrequencyActivePeriod(campaign)
                );
              });

              const notDraft = !removedReportIds.includes(reportId);

              return notArhivedAndInActivePeriod && notDraft;
            }
          );

          this.campaigns = indexBy(prop('_id'), shapedCampaigns);

          reports.forEach((report) => {
            this.campaignsNamesIndex[report._id] =
              this.campaigns[
                (report.contents as ReportCampaignContents).campaign_id
              ].contents.name;
          });

          return this.fetchPlaces(reports, campaigns);
        });
    }

    fetchPlaces(reports, campaigns) {
      const placesIds = uniq<ObjectId>(
        reports
          .map(({ contents }) => contents?.place_id)
          .filter((id) => Boolean(id)) as ObjectId[]
      );

      if (!placesIds?.length) {
        return;
      }

      const filters = { pos_id: { $in: placesIds } };

      return this.$q
        .all(
          campaigns.map(async (c) => {
            return this.placesSourceAdapterService.getPlacesOfCampaign.bind(
              this.placesSourceAdapterService,
              c,
              {
                filters: {
                  ...filters,
                  id: {
                    value: c.resolved?.subject_ids
                      ? c.resolved?.subject_ids
                      : [],
                    operation: 'equality',
                  },
                },
              }
            )();
          })
        )
        .then((placesR) => {
          let places: TSFixMe[] = [];

          placesR.forEach((p: TSFixMe) => {
            places = places.concat(p.entries);
          });

          this.places = places.reduce((acc, place) => {
            acc[place._id] = place.contents;

            return acc;
          }, {});
        });
    }

    /**
     * Hide drafts when their final reports have already been sent
     */
    hideSentReports(): void {
      const sentReportsIds = new Set(this.reports.map(({ _id }) => _id));

      this.drafts = this.drafts.filter(
        (draft) => !sentReportsIds.has(draft._id)
      );
    }

    /* Reports fetch function */

    initReports(): void {
      this.infiniteLoadError = false;

      this.requestPaginate.reset();

      if (this.contentKey) {
        this.contentService.scrollTopById(this.contentKey);
      }
    }

    callData(): ng.IPromise<void> {
      this.isLoading = true;

      return this.$q
        .all([
          this.fetchDrafts().catch(() => this.$q.resolve()),
          this.fetchToBeSent().catch(() => this.$q.resolve()),
          this.getReports(),
        ])
        .then(() => {
          this.isEmpty =
            !this.reports.length &&
            !this.drafts.length &&
            !this.toBeSentReports.length;

          this.hideSentReports();
        })
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    getReports(): ng.IPromise<Report[]> {
      this.infiniteLoadError = false;

      const reqParams = {
        ...this.reportsParams,
        ...this.composeFilterParamsReq(),
      };
      const requestConfig = [false, { pov: 'organisation' }];

      return this.requestPaginate
        .call(reqParams, ...requestConfig)
        .then(({ entities }) => {
          this.reports = entities;
          this.allCampaigns = {
            ...this.requestPaginate.getRelatedEntities('campaigns'),
            ...this.allCampaigns,
          };
          this.places = {
            ...this.places,
            ...this.requestPaginate.getRelatedEntities('places'),
          };
          this.users = {
            ...this.users,
            ...this.requestPaginate.getRelatedEntities('users'),
          };
          this.canCallMore = this.requestPaginate.canCallMore();

          entities.forEach((report) => {
            this.campaignsNamesIndex[report._id] =
              this.allCampaigns[
                (report.contents as ReportCampaignContents).campaign_id
              ].contents.name;
          });

          return entities;
        })
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        })
        .finally(() => {
          if (this.onScrollComplete) {
            this.onScrollComplete();
          }
        });
    }

    resetInfiniteLoadErrorState(): void {
      this.infiniteLoadError = false;
    }

    /* POV switch */

    changeReportsPov(index: number): ng.IPromise<Report[]> {
      this.networkError = false;
      this.isSearchLoading = true;
      this.searchError = false;
      this.searchField = '';

      this.activatePovTabByIndex(index);
      this.initReports();

      return this.getReports()
        .then((reports) => {
          this.isEmpty =
            !this.reports.length &&
            !this.drafts.length &&
            !this.toBeSentReports.length;
          return reports;
        })
        .catch(() => {
          this.searchError = true;
          return [];
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    }

    activatePovTabByIndex(index: number): void {
      this.values = this.values.map((value, i) => ({
        ...value,
        active: i === index,
      }));
    }

    /* Filters helpers */

    composeFilterParamsReq(): { filters: QueryParams } {
      return this.apiUtilsService.buildFilterParams(this.buildFilters(), {
        search: this.searchField,
        criterias: REPORTS_LIST_SEARCH_CRITERIAS,
      });
    }

    buildFilters(): ReportFilter[] {
      const emptyStateFilter = {
        name: 'report_state',
        value: 'empty',
        operator: '$ne',
      };
      const mineFilter = this.isMineView()
        ? [
            {
              name: 'user_id',
              value: this.userId,
              operator: '$eq',
            },
          ]
        : [];
      const campaignFilter = this.campaignId
        ? [{ name: 'campaign_id', value: this.campaignId, operator: '$eq' }]
        : [];
      const placeFilter = this.placeId
        ? [{ name: 'report_pos_id', value: this.placeId, operator: '$eq' }]
        : [];

      return [
        emptyStateFilter,
        ...mineFilter,
        ...campaignFilter,
        ...placeFilter,
      ];
    }

    /* Navigation */

    editDraft(report: Report): TransitionPromise {
      const stateParams = {
        reportId: report._id,
        campaignId: (report.contents as ReportCampaignContents).campaign_id,
        answers: this.isDraftReportsFlagEnabled
          ? JSON.stringify(report.contents.answers)
          : null,
        locationId: report.contents.place_id,
      };

      return this.$state.go(
        'index.menu-more.reactive-campaigns.form',
        stateParams
      );
    }

    redirectToFinalize(report: Report): TransitionPromise {
      const stateParams = {
        reportId: report._id,
        campaignId: (report.contents as ReportCampaignContents).campaign_id,
        shouldRedirectToFinalize: true,
      };

      return this.$state.go(
        'index.menu-more.reactive-campaigns.form',
        stateParams
      );
    }

    goToReport(report: Report): TransitionPromise {
      return this.$state.go('index.menu-more.reactive-campaigns.report', {
        reportId: report._id,
        campaignId: (report.contents as ReportCampaignContents).campaign_id,
        online: true,
      });
    }

    /* Helpers */

    isMineView(): boolean {
      return this.values[1].active;
    }

    getCommentsNumber(report: Report): number {
      return (
        (report.statisticsDigest && report.statisticsDigest.commentsCount) || 0
      );
    }

    getCampaign(report: Report): Identified<Campaign> {
      return this.allCampaigns[
        (report.contents as ReportCampaignContents).campaign_id
      ];
    }

    getPlace(report: Report): Partial<APIStoreContents> | undefined {
      return report.contents.place_id
        ? this.places[report.contents.place_id]
        : undefined;
    }
    getUser(report: Report): Partial<UserContents> {
      return this.users[report.contents.user_id];
    }

    getTagsMap(report: Report): Tag[] {
      const campaign = this.getCampaign(report);

      return campaign?.contents?.tagsMap ?? this.emptyTagsMap;
    }

    getCampaignName(report: Report): string {
      const campaign = this.getCampaign(report);

      return campaign?.contents?.name;
    }

    isChecklistObjectiveReached(report: Report, userId): boolean {
      const campaign = this.getCampaign(report);

      return !this.reportsService.shouldReportBeAllowedWithObjective(
        report,
        campaign,
        userId
      );
    }

    getTranslations(report: Report): TranslationDictionnary | undefined {
      const campaign = this.getCampaign(report);

      return campaign?.i18n;
    }
  },
};
