import { User } from '../../../..';
import { CampaignsUtilsService } from '../../../../reactive-campaigns/services/campaigns-utils.service';
import { Campaign } from '../../../../services/API/campaigns/campaigns';
import { Report } from '../../../../services/API/reports/reports';
import { DateService } from '../../../../services/Utils/Dates/date.service';
import { PubSubService } from '../../../../services/Utils/PubSub/pubsub.service';
import { PlaceService } from '../../services/place.service';

export class PlaceCampaignsController {
  // bindings
  place: any;
  organisation: any;
  profile: User;

  // private props
  formListener: () => void;
  dataListener: () => void;
  campaigns: Campaign[] = [];
  campaignsById: Record<string, Campaign> = {};
  backups: Report[] = [];
  loadingCampaigns = false;
  isBrowser = false;

  // eslint-disable-next-line max-params
  constructor(
    private helpersService,
    private pubSubService: PubSubService,
    private dateService: DateService,
    private placeService: PlaceService,
    private platformService,
    private campaignsUtilsService: CampaignsUtilsService
  ) {
    'ngInject';
    this.formListener = this.pubSubService.subscribe(
      'FORM_REPORT_UPDATED',
      () => this.callData()
    );
    this.dataListener = this.pubSubService.subscribe(
      this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
      () => this.callData()
    );
    this.isBrowser = this.platformService.isBrowser();
  }

  $onInit(): ng.IPromise<void> {
    this.loadingCampaigns = true;

    return this.callData();
  }

  $onDestroy(): void {
    this.formListener();
    this.dataListener();
  }

  callData(): ng.IPromise<void> {
    return this.placeService
      .getPlaceCampaignsData(this.place._id, this.profile._id)
      .then(({ reports, campaignsWithGoal, campaignsWithoutGoal }) => {
        this.backups = reports;
        this.campaigns = [
          ...campaignsWithGoal.sort((a, b) =>
            this.helpersService.sortNatural(a.contents.name, b.contents.name)
          ),
          ...campaignsWithoutGoal.sort((a, b) =>
            this.helpersService.sortNatural(a.contents.name, b.contents.name)
          ),
        ].filter((campaign) =>
          this.campaignsUtilsService.isFrequencyActivePeriod(campaign)
        );

        this.campaignsById = {};
        this.campaigns.forEach((campaign) => {
          this.campaignsById[campaign._id] = campaign;
        });
      })
      .finally(() => {
        this.loadingCampaigns = false;
      });
  }

  orderByDate(entityA: Campaign, entityB: Campaign): number {
    return this.dateService.compareDates(
      entityA.created_date,
      entityB.created_date
    );
  }
}
