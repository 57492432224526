import { SfFeatureFlags } from '@simplifield/feature-flags';
import { StateObject, StateService } from '@uirouter/angularjs';
import { APIStore } from '..';
import { ObjectId, TSFixMe, User } from '../..';
import { ContentService } from '../../components/Layout/content/content.service';
import {
  FilterCustomParam,
  FilterSelected,
  FilterStandard,
} from '../../components/Search/search';
import { FEATURE_FLAGS } from '../../constants/feature-flags.constant';
import {
  HAS_NEW_PARAM_FILTERS,
  PLACES_FILTERS_KEY,
  PLACES_SORTERS_KEY,
} from '../../constants/place-filters.constant';
import { PlacesParamsService } from '../../services/API/places-params/places-params.service';
import {
  GeolocationService,
  LatLng,
} from '../../services/Utils/Geolocation/geolocation.service';
import { GoogleMapService } from '../../services/Utils/GoogleMap/google-map.service';
import { LocalizationService } from '../../services/Utils/Localization/localization.service';
import { Modal, ModalService } from '../../services/Utils/Modal';
import {
  PubSubService,
  UnregisterFn,
} from '../../services/Utils/PubSub/pubsub.service';
import { SegmentService } from '../../services/Utils/Segment/segment.service';
import { PlacePopupService } from '../place/services/place-popup.service';
import { SF_PLACE_SORTERS } from '../places.config';
import { buildPlacesParams, initPlacesFilters } from './helpers';

const NB_PLACES_LOADED = 20;
type ReqPaginate = {
  call: (params: unknown) => ng.IPromise<{ entities: APIStore[] }>;
  removeItem: (item: unknown) => void;
  canCallMore: () => boolean;
  reset: () => void;
};
// type Filter = { id: unknown; label: unknown; values?: string[], paramType?: CustomParamType };

export const PlacesComponent = {
  bindings: {
    profile: '<',
  },
  templateUrl: 'places/places/places.html',
  // eslint-disable-next-line max-params
  controller: class PlacesController {
    // bindings
    profile: User;

    //class members
    places: APIStore[];
    placeSearch: string;
    sorters: typeof SF_PLACE_SORTERS;
    currentSort: typeof SF_PLACE_SORTERS[number];
    savedSort: typeof SF_PLACE_SORTERS[number];
    filters: (FilterStandard | FilterCustomParam)[];
    placesFilters: FilterSelected[];
    dataListener: UnregisterFn;
    dataFailedListener: UnregisterFn;
    placesSynchronizationListener: UnregisterFn;
    onlineStoresPrefChangeListener: UnregisterFn;
    isLoading: boolean;
    isSearchLoading: boolean;
    networkError: boolean;
    infiniteLoadError: boolean;
    searchError: boolean;
    requestPaginate: ReqPaginate;
    placesListsNameHash: Record<string, { contents: { places_ids: string[] } }>;
    canAddPlaces: boolean;
    userPosition: LatLng | null;
    canSyncMore: boolean;
    isRTLNeeded: boolean;
    showGoogleMap: boolean;
    lastPlacesCallTimestamp: number;

    onScrollComplete: null | (() => void);
    placesParamsHash: Record<string, FilterCustomParam> = {};

    constructor(
      private localizationService: LocalizationService,
      private $q: ng.IQService,
      private $translate: ng.translate.ITranslateService,
      private pubSubService: PubSubService,
      private contentService: ContentService,
      private RequestsPaginate,
      private placesSourceAdapterService,
      private geolocationService: GeolocationService,
      private modalService: ModalService,
      private segmentService: SegmentService,
      private localStorageService: ng.local.storage.ILocalStorageService,
      private placesParamsService: PlacesParamsService,
      private placesListsService,
      private sfFeatureFlagsService: SfFeatureFlags,
      private PLACE_SORTERS: typeof SF_PLACE_SORTERS,
      private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
      private placesService,
      private googleMapService: GoogleMapService,
      private placePopupService: PlacePopupService,
      private $state: StateService,
      private $timeout: ng.ITimeoutService
    ) {
      'ngInject';
    }

    $onInit(): ng.IPromise<void | APIStore[]> {
      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      const getLocalData = (key, fallbackData) =>
        this.localStorageService.get(key) || fallbackData;

      let savedFilter = [];
      // it's straightforward, but we need to delete old params only once after upgrade of the app with new params
      const hasNewFilters = this.localStorageService.get(HAS_NEW_PARAM_FILTERS);
      if (hasNewFilters) {
        savedFilter = getLocalData(PLACES_FILTERS_KEY, []);
      } else {
        this.localStorageService.set(HAS_NEW_PARAM_FILTERS, true);
      }

      this.places = [];
      this.placeSearch = '';
      this.sorters = this.PLACE_SORTERS;
      this.currentSort = this.PLACE_SORTERS[1];
      this.savedSort = getLocalData(PLACES_SORTERS_KEY, this.currentSort);
      this.filters = [];
      this.placesFilters = savedFilter;
      this.dataListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED,
        () => this.reload()
      );
      this.dataFailedListener = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.DATA_SYNCED_FAILED,
        () => this.reload()
      );
      this.placesSynchronizationListener = this.pubSubService.subscribe(
        'PLACES_SYNCHRONIZATION_UPDATED',
        () => this.reload()
      );
      this.onlineStoresPrefChangeListener = this.pubSubService.subscribe(
        'ONLINE_STORES_PREFERENCE_CHANGED',
        () => this.reload()
      );
      this.isAllowedToAddPlace();
      this.googleMapService.load();

      return this.localizationService
        .shouldUseChinaConfiguration()
        .then((shouldUseChinaConfiguration) => {
          this.showGoogleMap = !shouldUseChinaConfiguration;
          return this.initializePlaces();
        });
    }

    requestPlaces(): (p: Record<string, TSFixMe>) => ng.IPromise<APIStore[]> {
      return this.placesSourceAdapterService.queryFromDataSource.bind(
        this.placesSourceAdapterService
      );
    }

    resetRequestPaginateFunction(): void {
      const requestFn = this.requestPlaces();

      this.requestPaginate = new this.RequestsPaginate(requestFn, {
        limit: NB_PLACES_LOADED,
      });
    }

    initializePlaces(): ng.IPromise<void | APIStore[]> {
      this.isLoading = true;
      this.isSearchLoading = false;
      this.networkError = false;
      this.infiniteLoadError = false;
      this.searchError = false;

      this.resetRequestPaginateFunction();

      return this.$q
        .all([
          this.placesParamsService.listFiltersParams(),
          this.placesListsService.listLocal(),
        ])
        .then(([placesParams, placesLists]) => {
          const { filters, placesListsNameHash, placesParamsHash } =
            initPlacesFilters(placesParams, placesLists, this.$translate);

          this.filters = filters;
          this.placesListsNameHash = placesListsNameHash;
          this.placesParamsHash = placesParamsHash;
        })
        .then(() => this.reload())
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    refresh(): ng.IPromise<void | APIStore[]> {
      this.placesSourceAdapterService.setRemoteSource();
      return this.initializePlaces();
    }

    $onDestroy(): void {
      this.dataListener();
      this.dataFailedListener();
      this.placesSynchronizationListener();
      this.onlineStoresPrefChangeListener();
    }

    isAllowedToAddPlace(): ng.IPromise<void> {
      return this.placesService.isAllowedToSave().then((isAllowed) => {
        this.canAddPlaces =
          this.sfFeatureFlagsService.hasFeature(
            this.SF_FEATURE_FLAGS.ADD_PLACE
          ) && isAllowed;
      });
    }

    reload(): ng.IPromise<APIStore[]> {
      this.initPlaces();
      this.resetRequestPaginateFunction();

      return this.$q
        .all([
          this.isAllowedToAddPlace(),
          this.geolocationService.getCurrentPosition(),
        ])
        .then(([, position]) => {
          this.userPosition = position;
          return this.getPlaces();
        });
    }

    initPlaces(): void {
      this.userPosition = null;
      this.places = [];
      this.infiniteLoadError = false;
      this.canSyncMore = this.localStorageService.get(
        'is_places_sync_incomplete'
      );

      this.requestPaginate.reset();

      this.contentService.scrollTopById('placesScroll');
    }

    getPlacesParams(): Record<string, TSFixMe> {
      return buildPlacesParams(
        this.placeSearch,
        this.placesFilters,
        this.placesParamsHash,
        this.placesListsNameHash,
        this.currentSort,
        this.userPosition
      );
    }

    getPlaces(): ng.IPromise<APIStore[]> {
      this.infiniteLoadError = false;
      const timestamp = new Date().getTime();
      this.lastPlacesCallTimestamp = timestamp;

      return this.requestPaginate
        .call(this.getPlacesParams())
        .then((places) => {
          if (this.lastPlacesCallTimestamp === timestamp) {
            this.places = places.entities;
          }
          return this.places;
        })
        .catch((err) => {
          this.infiniteLoadError = true;
          throw err;
        })
        .finally(() => {
          if (
            this.onScrollComplete &&
            !this.searchError &&
            this.requestPaginate.canCallMore()
          ) {
            this.onScrollComplete();
          }
        });
    }

    resetInfiniteLoadErrorState(): void {
      this.infiniteLoadError = false;
    }

    openSortModal(): Modal {
      const template = `
        <sf-sort-modal
          value="$ctrl.value"
          options="$ctrl.options"
          on-change="$ctrl.onChange(option)"
          on-close="$ctrl.onClose()">
        </sf-sort-modal>
      `;
      const sortBindings = {
        value: this.currentSort,
        options: this.sorters,
        onChange: (option) => this.onSortChange(option),
      };

      this.segmentService.track('PLACES', {
        action: 'open',
        label: 'Sorting',
      });

      return this.modalService.open(template, sortBindings, {
        animation: 'slide-in-top',
      });
    }

    onSortChange(
      option: typeof SF_PLACE_SORTERS[number]
    ): ng.IPromise<APIStore[] | void> {
      this.currentSort = option;
      this.isSearchLoading = true;
      this.searchError = false;

      this.localStorageService.set(PLACES_SORTERS_KEY, option);
      return this.reload()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    }

    onPlaceSearchChange(search: string): ng.IPromise<APIStore[] | void> {
      this.placeSearch = search;
      this.isSearchLoading = true;
      this.searchError = false;

      return this.reload()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    }

    onFilterChange(filters: FilterSelected[]): ng.IPromise<APIStore[] | void> {
      this.placesFilters = filters;
      this.isSearchLoading = true;
      this.searchError = false;

      this.localStorageService.set(PLACES_FILTERS_KEY, filters);
      return this.reload()
        .catch(() => {
          this.searchError = true;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    }

    openMap(): void {
      const requestFn = this.requestPlaces();

      requestFn(this.getPlacesParams()).then(({ entries: places }) => {
        const template = `
          <sf-places-map-modal
            places="$ctrl.places"
            filters="$ctrl.filters"
            filters-available="$ctrl.filtersAvailable"
            on-close="$ctrl.onClose()">
          </sf-places-map-modal>
        `;

        const mapBindings = {
          places,
          filters: this.placesFilters,
          filtersAvailable: this.filters,
        };

        this.segmentService.track('PLACES', {
          action: 'open',
          label: 'Places map',
        });

        return this.modalService.open(template, mapBindings);
      });
    }

    openSyncPlacesListModal(): Modal {
      const template = `
        <sf-places-synchronization-modal
          on-close="$ctrl.onClose()">
        </sf-places-synchronization-modal>
      `;

      return this.modalService.open(template, {});
    }

    addPlace(): ng.IPromise<void | StateObject> {
      this.segmentService.track('PLACES', {
        action: 'open',
        label: 'Adding',
      });

      return this.modalService
        .openAsPromise<{ placeId: ObjectId }>(
          `
        <sf-place-add
          profile="$ctrl.profile"
          on-save="$ctrl.onSave()"
          on-close="$ctrl.onClose()">
        </sf-place-add>`,
          { profile: this.profile },
          { hardwareBackButtonClose: false }
        )
        .then(({ placeId }) =>
          // modal need time to close with animations and only then body is unblocked - consider to rework openAsPromise without timeouts
          // https://github.com/dwmkerr/angular-modal-service/issues/41#issuecomment-76201337 - we don't use this lib but issue is the same
          this.$timeout(
            () =>
              this.placePopupService
                .showParamsEditPrompt()
                .then(({ shouldEditParams }) =>
                  this.$state.go('index.places.details.main', {
                    placeId,
                    shouldEditParams,
                  })
                ),
            500
          )
        );
    }
  },
};
