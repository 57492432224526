const GEOLOC_PERMISSION_CODE_ERRORS = 1;
const GEOLOC_TIMEOUT_CODE_ERRORS = 3;
const GEOLOC_CODE_ERRORS = [
  GEOLOC_PERMISSION_CODE_ERRORS,
  GEOLOC_TIMEOUT_CODE_ERRORS,
];

export class LogService {
  ignoreException(error) {
    if (!error || this.checkNetworkException(error)) {
      return true;
    }
    if (this.checkNetworkException(error)) {
      return true;
    }

    return !!this.checkGeolocTimeoutError(error);
  }
  checkNetworkException(error) {
    return {}.undef !== error.status;
  }
  checkGeolocTimeoutError(error) {
    return error.code && GEOLOC_CODE_ERRORS.indexOf(error.code) !== -1;
  }
}

export function LogExceptionHandler(
  $delegate,
  $log,
  sentryService,
  datadogService,
  logService
) {
  'ngInject';
  return (error, cause) => {
    if (!logService.ignoreException(error)) {
      try {
        sentryService.captureException(error);
        datadogService.addError(error);
      } catch (e) {
        $log.error('Log Error:', e);
      }
    }
    return $delegate(error, cause);
  };
}
