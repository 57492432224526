import { PlacesMapComponent } from './components/places-map-modal/places-map-modal.component';
import { PlacesSynchronizationComponent } from './components/places-synchronization-modal/places-synchronization-modal.component';
import { PlacesComponent } from './places.component';
import { SF_PLACE_SORTERS } from '../places.config';

export default angular
  .module('sfMobile.components.places', [
    'sfMobile.services.localization',
    'sfMobile.services.places',
    'sfMobile.services.geolocation',
    'sfMobile.services.modal',
    'sfMobile.services.segment',
    'sfMobile.components.content',
    'sfMobile.components.view',
    'sfMobile.components.view-states',
    'sfMobile.components.view-states-search',
    'sfMobile.components.button-header-close',
    'sfMobile.components.sort-modal',
    'sfMobile.components.sort-button',
    'sfMobile.components.button-floating',
    'sfMobile.components.google-map-modal',
    'sfMobile.components.place-item',
    'sfMobile.services.requests-paginate',
    'sfMobile.services.pubsub',
  ])
  .constant('PLACE_SORTERS', SF_PLACE_SORTERS)
  .component('sfPlacesMapModal', PlacesMapComponent)
  .component('sfPlacesSynchronizationModal', PlacesSynchronizationComponent)
  .component('sfPlaces', PlacesComponent).name;
